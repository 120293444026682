import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack,Grid} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import axios from "axios";
import swal from "sweetalert";
import dayjs from 'dayjs';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from 'react-router-dom';

const wider ={
  width : '99%',
}
const api_uri = process.env.REACT_APP_API_URL

function SurveyResults() {
    
  const [surveyid, setsurveyid] = useState("")
  const [showdash, setshowdash] = useState(false)

  
  const [surveylist, setsurveylist ]=useState([])
    const [Resultlist, SetResultdata] = useState([])
    const navigate = useNavigate();

    const onChoose=(e)=>{
      // console.log(e.target.value)
      let surveyid=e.target.value
      setsurveyid(e.target.value)

      fetch(api_uri+"/resultsingle/"+surveyid)
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    SetResultdata(res)           
                }).catch((error) => {
                    
                });

    }

    const call_fetch = () =>{
      fetch(api_uri+"/surveylist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
              setsurveylist(res) 
              if(res.length > 0){
                setsurveyid(res[(res.length-1)].id)
                let surveyid=res[(res.length-1)].id
                

                fetch(api_uri+"/resultsingle/"+surveyid)
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    SetResultdata(res)           
                }).catch((error) => {
                    
                });
              }          
            }).catch((error) => {
                
            });


      
    }
    const onDelete = (e) => {
      const id = e.row.id;
      swal({
        title: "Are you sure you want to delete this response?",
        text: "",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) { 
            axios.get(api_uri+"/resultdelete/"+id, {
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
              }
              })
              .then((response) => {
                call_fetch()
                toast.success("Record has been deleted !", {position: "top-right",theme: "colored"});
              })
              .catch((error) => {
                  swal("", "Fail to delete the record", "error",{ button: "Try Again!",});
              });
        }
      })
    };
    const onClickQes = (e) => {
        let id = e.row.id
        
        navigate('/admin/survey-answer?survey='+id);
      }

    useLayoutEffect(() => {    
      
      
        call_fetch()
        
    },[]);
    
    
    const columns = [
      // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
      { field: "email", headerName: "Vendor Email", headerClassName: 'super-app-theme--header', width: 250}, 
      { field: "sent", headerName: "Status", headerClassName: 'super-app-theme--header', width: 180, align:'center',headerAlign:'center',
        renderCell:(params) => {
              if(params.value===0){
              return  <div> <span className='badge-blue'>Newly Added</span></div>
              }else{
                return  <div><span className='badge-green'>From Survey Sent</span></div>
              }

          },
      },
      { field: "title", headerName: "Survey", headerClassName: 'super-app-theme--header',width: 300,headerAlign:'center', align:'center' },
      { field: "CreatedDt", headerName: "Date Completed" , headerClassName: 'super-app-theme--header', width: 200 ,valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY')  },
      {
        field: 'action',
        headerName: 'Action',
        headerAlign:'center',
        align:'center',
        
        width: 200,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            
            
            return (
              <Stack direction="row" spacing={2} justifyItems={'center'}>
                <Button className='adm-btn btg' variant="contained" style={{background:'#4caf50'}} size="small" onClick={()=> onClickQes(params)}><ViewQuiltIcon/>View Responses</Button>
                <IconButton variant="outlined" color="error" size="small" onClick={()=> onDelete(params)}><DeleteIcon/></IconButton>
              </Stack>
            );
        },
      }
    ];
    const handleDeleteall = () => {
       
      const listid =selectedRows.join(',')
      if(listid){
        swal({
          title: "Are you sure you want to delete?",
          text: "",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {           
                  
            axios.post(api_uri+"/resultdeleteitems", {            
              ids:listid
          
              },{
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
              }
              })
              .then((response) => {
                call_fetch()
                if(selectedRows.length >1){
                  swal("Records Deleted!", "", "success",{ button: "Close",});
                }else{
                  swal("Record Deleted!", "", "success",{ button: "Close",});
                }
      
      
              })
              .catch((error) => {
                  swal("", "Failed to delete selected records!", "error",{ button: "Try Again!",});
              });
          } else {
            
          }
        });
      }else{
        swal("Please select records", "",{ button: "OK",});
      }
      

    }
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const options = surveylist.map((obj)=>
        <MenuItem key={obj.id}  value={obj.id}><strong>{obj.title}</strong></MenuItem>
      );
    return (
      <Box p="20px" >
        <Header
          title="Vendor Responses"
          subtitle="Dashboard / Vendor Responses"
        />
        <Grid container spacing={2}>
          
          <Grid item xs={12} md={6}>
            <div className='selectsurvey'>Select Survey :</div>
            <FormControl sx={{ m: 1, width: '100%' }} className='sel-sur-dash'>
              <Select 
              value={surveyid}

                onChange={onChoose}
                displayEmpty
                
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {options}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} justifyContent={'center'}  marginTop={5}>
              
              <Button onClick={handleDeleteall} variant="contained" color="error" startIcon={<DeleteIcon />}>
                Delete Items
              </Button>
              
            </Stack>
          </Grid>
          </Grid>
        
        <Box
          m="20px 0 0 0"
          className="bg-white"
          sx={wider}
          
        >
          <DataGrid checkboxSelection disableRowSelectionOnClick
            rows={Resultlist}
            columns={columns}
            pageSizeOptions={[10, 25,50,100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(ids) => {
                
              setSelectedRows(ids);
            }}
          />
        </Box>
        
        <ToastContainer autoClose={1000}/>
      </Box>
    );
  };

export default SurveyResults



