import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack , Grid, Card} from "@mui/material";
import { useLocation } from 'react-router-dom';
import Header from "./Header";

import axios from "axios";
import swal from "sweetalert";
// import dayjs from 'dayjs';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PinIcon from '@mui/icons-material/Pin';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListIcon from '@mui/icons-material/List';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SaveIcon from '@mui/icons-material/Save';

import CloseIcon from '@mui/icons-material/Close';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Qespage from './Qespage';

const api_uri = process.env.REACT_APP_API_URL


const styleadd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'white',
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb:4,
  pt:1,
};

function SurveyQes() {
  
  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("survey")

  

  
  const bank = {
    surveyid:id,
    elements:[],

  };

  const singlesurvey = {
    title:"",

  };
 

 
  

  const [singleData, setsingleData] = useState(singlesurvey)
  const [formData, setFormData]= useState(bank);

  const [qtype,setqtype]=useState('text')
  const [choice,setchoice]=useState(false)
  const [choicelength, setchoicelength] = useState(2);
  const [countQes, setcountQes] = useState(1);
  const [inputList, setInputList] = useState([]);
  const [expand,setexpand]=useState(false)

  const [openadd, setOpenadd] = React.useState(false);
  const handleOpenadd = () => setOpenadd(true);
  const handleCloseadd = () => {
    setOpenadd(false)
    setInputList([])
  };

  
  const call_fetch = () =>{
    fetch(api_uri+"/survey/"+id)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
            setsingleData(res[0])   
            if(res[0].questions){
              setFormData(JSON.parse(res[0].questions))
            }        
          }).catch((error) => {
              
          });
  }
  
    const createQes = (type) => {
       if(type==='radio' || type==='checkbox' || type==='multiboolean'){
        setchoice(true)
        setchoicelength(2)
       }else{
        setchoice(false)
       }
        setexpand(false)
        setqtype(type)
        handleOpenadd()
    };
    const handletoggele =()=>{
      setexpand(!expand)
    }

  useLayoutEffect(() => {    
      
      
    call_fetch()
    
  },[]);

  const AddNewpage =(event)=> {
    event.preventDefault();
    const array = formData['elements']

    let arraylength =String(countQes).padStart(2, '0')
    
    if(event.target.qtype.value==='radio' || event.target.qtype.value==='checkbox' || event.target.qtype.value==='multiboolean'){
      const choices = []
     let j=1;
      for(let k=1;k<=choicelength;k++){
        let optio ='cho'+k

        if(event.target.elements[optio].value){
          const opt = {
            value:j,
            text:event.target.elements[optio].value
          }
          choices.push(opt)
          j++;

        }
      }
      const qesarr = {
        type:event.target.qtype.value,
        // name:event.target.name.value,
        title:event.target.qestitle.value,
        description:event.target.description.value,
        note:event.target.note.value,
        choices:choices,
        qesid: 'qes_'+new Date().getTime()
      }
      array.push(qesarr)

    }else{
      const qesarr = {
        type:event.target.qtype.value,
        // name:event.target.name.value,
        title:event.target.qestitle.value,
        description:event.target.description.value,
        note:event.target.note.value,
        qesid: 'qes_'+new Date().getTime()
      }
      array.push(qesarr)
    }  
    
    


    setFormData({...formData, elements: array})
    // call_fetch()
    saveqes();
    handleCloseadd();
    setcountQes(countQes+1);

  }

  const addchoice = () =>{
    setchoicelength(inputList.length+3)
    setInputList(inputList.concat(<Child id={inputList.length+3} key={inputList.length} />));

  }
  const saveqes = () =>{
      axios.post(api_uri+"/surveyupdatequestion/",{  
      id:id,
      questions:formData
      }, {
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          call_fetch();
          toast.success("Survey questions saved suucessfully!", {position: "top-right",theme: "colored"});
        })
        .catch((error) => {
            swal("", "Fail to save the survey  questions", "error",{ button: "Try Again!",});
        });
  }
  const Question = formData.elements.map((qes,index)=> 
      
  <Qespage key={index} id={index+1} props={qes} formData={formData} setFormData={setFormData}/>
    

  )
 
  

  return (
    <Box p="20px" >
        <Header
          title={"Title : "+singleData.title}
          subtitle="Dashboard / Surveys / Survey Questions"
        />

        
        <Box
          m="20px"
          className="bg-white" 
        >
          <Grid container spacing={0} display="flex" justifyContent={'center'}>
            <Grid item xs={12} md={12}   >
              <Card className="sur-card">
                  
                  
                {Question}
                

                  <div style={{textAlign:'right'}}>
                  <Button color='success' variant='contained' onClick={saveqes} startIcon={<SaveIcon/>}> Save</Button>
                  </div>
                </Card>
            </Grid>
          </Grid>

        </Box>


        <Stack direction="row" margin={3} padding={2} bgcolor={'#fff'} border={'1px dashed'} spacing={2} justifyContent={'center'} >
            <div className="dropdowns">
              <Button 
                      
                      id="composition-button"
                      variant="contained" 
                      onClick={handletoggele}
                      style={{backgroundColor:'#4CAF50'}} 
                      startIcon={<AddIcon />} 
                      endIcon={<ArrowDropDownCircleIcon />}>
                  Add Question
                </Button>
                <div className={"dropdowns-content "+(expand ? ' expand':'') }>
                    <div className="menus" onClick={()=>createQes('text')} ><BorderColorIcon style={{marginRight:10}}/>  Single Line Text</div>
                    <div className="menus" onClick={()=>createQes('radio')} ><ListIcon style={{marginRight:10}}/> Radio Buttons</div>
                    <div className="menus" onClick={()=>createQes('checkbox')}><CheckBoxIcon style={{marginRight:10}}/> Checkboxes</div>                    
                    <div className="menus" onClick={()=>createQes('multiboolean')} ><ListIcon style={{marginRight:10}}/> Multiple SubChoices</div>
                    <div className="menus" onClick={()=>createQes('textarea')}><TextSnippetIcon style={{marginRight:10}}/> Multi Line Text</div>
                    <div className="menus" onClick={()=>createQes('email')}><AlternateEmailIcon style={{marginRight:10}}/> Input Email</div>
                    <div className="menus" onClick={()=>createQes('number')}><PinIcon style={{marginRight:10}}/> Input Numbers</div>
                </div>
            </div>
          
          
        </Stack>

         
        


        
        <Modal
            open={openadd}
            onClose={handleCloseadd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Add New Question</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseadd}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={AddNewpage}>
                <div>
                  <div className='scroll-div'>
                    {/* <div className='group-control'>
                      <label className="clabel">Name <span className='req-label'>*</span>:</label>
                      <input type="text" className="form-control" name="name" id="name" placeholder='qestion1'  required/>
                    </div> */}
                    <div className='group-control'>
                      <label className="clabel">Question <span className='req-label'>*</span>:</label>
                      <textarea className="form-control" name="qestitle" id="qestitle" placeholder='Enter Full Question' required ></textarea>
                      
                    </div>

                    <div className={'group-control '+(choice? '':'d-none')}>
                      <label className="clabel"> Options </label>
                      <div className='choice'><label>Choices 1:</label><textarea className="form-control" id="cho1" name="cho1" placeholder='option' ></textarea></div>
                      <div className='choice'><label>Choices 2:</label><textarea className="form-control" id="cho2" name="cho2" placeholder='option' ></textarea></div>
                      {inputList}
                      <div className="addchoice" onClick={addchoice}> <AddCircleOutlineIcon/> Add new option</div>
                    </div> 
                    <div className='group-control'>
                      <label className="clabel">Short Description/Instruction :</label>
                      <textarea className="form-control" name="description" id="description"placeholder='Short Description/Instruction' ></textarea>
                    </div>
                    
                    <div className='group-control'>
                      <label className="clabel">Note :</label>
                      <textarea className="form-control" name="note" id="note"placeholder='Notes' ></textarea>
                    </div> 
                  </div>
                  
                  <div className='bottom-control'>
                    <input type='hidden' value={qtype} name="qtype"/>
                    <Button type="submit" variant='contained' color='info'>Add</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
        
        <ToastContainer autoClose={1000}/>
      </Box>
  )
}


export default SurveyQes

const Child = ({id}) =>{
  return  <div className='choice'><label>Choices {id}:</label><textarea className="form-control" id={'cho'+id} name={'cho'+id} placeholder='option' ></textarea></div>
}



