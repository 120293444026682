import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import axios from "axios";
import swal from "sweetalert";
import dayjs from 'dayjs';

import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import IconButton from '@mui/material/IconButton';

import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from 'react-router-dom';

const wider ={
  width : 'auto',
  minWidth :'90%'
  
}
const api_uri = process.env.REACT_APP_API_URL

function SurveyReports() {
  
    const [Resultlist, SetResultdata] = useState([])
    const navigate = useNavigate();

    const call_fetch = () =>{
      fetch(api_uri+"/surveyreport")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                SetResultdata(res)           
            }).catch((error) => {
                
            });
    }
   
    const onClickQes = (e) => {
        let id = e.row.id
        
        navigate('/admin/survey-summary?survey='+id);
      }
    const onClickview = (e) => {
        let id = e.row.id
        
        navigate('/admin/reports?survey='+id);
      }

    useLayoutEffect(() => {    
      
      
        call_fetch()
        
    },[]);
    
    
    const columns = [
      // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
      { field: "title", headerName: "Survey", headerClassName: 'super-app-theme--header',width:350 },
      { field: "response", headerName: "Total Response", headerClassName: 'super-app-theme--header',headerAlign:'center',align:'center',width:300},
      {
        field: 'action',
        headerName: 'Action',
        headerAlign:'center',
        align:'center',
        
        width: 300,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            
            
            return (
              <Stack direction="row" spacing={2} justifyItems={'center'}>
                <Button variant="contained" className='adm-btn btg' style={{background:'#4caf50'}} size="small" onClick={()=> onClickQes(params)}><ViewQuiltIcon/>  Response Summaries</Button>
                <Button variant="contained" className='adm-btn btg' style={{background:'#036c60'}} size="small" onClick={()=> onClickview(params)}><LibraryBooksIcon/>  View Report</Button>
                
              </Stack>
            );
        },
      }
    ];
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
    return (
      <Box p="20px" >
        <Header
          title="Survey Responses"
          subtitle="Dashboard / Survey Responses"
        />
        <Stack direction="row" spacing={2} justifyContent={'flex-start'}>
            
          </Stack>
        <Box
          m="20px 0 0 0"
          className="bg-white"
          sx={wider}
          
        >
          <DataGrid checkboxSelection disableRowSelectionOnClick
            rows={Resultlist}
            columns={columns}
            pageSizeOptions={[10, 25,50,100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(ids) => {
                
              setSelectedRows(ids);
            }}
          />
        </Box>
        
        <ToastContainer autoClose={1000}/>
      </Box>
    );
  };

export default SurveyReports



