import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import axios from "axios";
import swal from "sweetalert";
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link, useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Modal from '@mui/material/Modal';



const wider ={
  width : '100%',
}
const api_uri = process.env.REACT_APP_API_URL
const styleadd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'white',
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb:4,
};

function Surveys() {
    const navigate = useNavigate();
    const [datalist, setdatalist] = useState([])
    const [openadd, setOpenadd] = React.useState(false);
    const handleOpenadd = () => setOpenadd(true);
    const handleCloseadd = () => setOpenadd(false);

    
    const [openedit, setOpenedit] = React.useState(false);
    const handleOpenedit = () => setOpenedit(true);
    const handleCloseedit = () => setOpenedit(false);
    const [editdata, seteditdata] = useState([])

    const call_fetch = () =>{
      fetch(api_uri+"/surveylist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setdatalist(res)           
            }).catch((error) => {
                
            });
    }
    const disableclick = (e) => {
      
              axios.post(api_uri+"/surveyupdatestatus/",{  
              id:e.row.id,
              status:'N'    
              }, {
                  headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
                })
                .then((response) => {
                  call_fetch()
                  toast.success("Disabled the survey form !", {position: "top-right",theme: "colored"});
                })
                .catch((error) => {
                    swal("", "Fail to disable the survey", "error",{ button: "Try Again!",});
                });
    };
    const enableclick = (e) => {
      
      axios.post(api_uri+"/surveyupdatestatus/",{  
      id:e.row.id,
      status:'Y'    
      }, {
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          call_fetch()
          toast.success("Enabled the survey form !", {position: "top-right",theme: "colored"});
        })
        .catch((error) => {
            swal("", "Fail to enable the survey", "error",{ button: "Try Again!",});
        });
};

    const onDelete = (e) => {
      const id = e.row.id;
      swal({
        title: "Are you sure you want to delete this survey?",
        text: "",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) { 
              axios.get(api_uri+"/surveydelete/"+id, {
                  headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
                })
                .then((response) => {
                  call_fetch()
                  toast.success("Record has been deleted !", {position: "top-right",theme: "colored"});
                })
                .catch((error) => {
                    swal("", "Fail to delete the record", "error",{ button: "Try Again!",});
                });
          }
        })
    };
    const onClickEdit = (e) => {
      seteditdata(e.row)
      handleOpenedit()
    };
    const onClickQes = (e) => {
      let id = e.row.id
      const quest = e.row.questions
      
      navigate('/admin/survey-questions?survey='+id);
    }
    const onClickCopy = (e) => {
      let slug = e.row.slug

      // Copy the text inside the text field
      const copy = require('clipboard-copy')
      copy(process.env.REACT_APP_URL+'survey-form?survey='+slug)
      // navigator.clipboard.writeText(process.env.REACT_APP_URL+'survey-form?survey='+slug);
      toast.success(e.row.title+" form link has been copied !", {position: "top-right",theme: "colored"});

    }

    useLayoutEffect(() => {    
      
      
        call_fetch()
        
    },[]);
    
    
    const columns = [
      // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
      { field: "title", headerName: "Survey Name", headerClassName: 'super-app-theme--header',width:400  },
      { field: "status", headerName: "Status", headerClassName: 'super-app-theme--header',
        
        headerAlign:'center',
        align:'center',
        renderCell:(params) => {
              if(params.value==='Y'){
              return  <Switch  onClick={()=> disableclick(params)} defaultChecked color='secondary'/>
              }else{
                return  <Switch  onClick={()=> enableclick(params)} color='secondary'/>
              }

          },
      },
      { field: "sort", headerName: "Sort" , headerClassName: 'super-app-theme--header',   },
      { field: "createdDt", headerName: "Created Date" , headerClassName: 'super-app-theme--header',  valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY')  },
      { field: "enddate", headerName: "End Date" , headerClassName: 'super-app-theme--header',  valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY')  },
      {
        field: 'action',
        headerName: 'Action',
        headerAlign:'center',
        align:'center',
        type: 'actions',
        width: 350,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            
            
            return (
              <Stack direction="row" spacing={2} justifyItems={'center'}>
                <Button variant="contained" style={{background:'#4caf50'}} size="small" onClick={()=> onClickQes(params)}><ViewQuiltIcon/> Questions</Button>
                <Button variant="contained" style={{background:'#ef6c00'}} size="small" onClick={()=> onClickCopy(params)}><ContentCopyIcon/> Copy Link</Button>
                
                <IconButton variant="outlined" color="warning" size="small" onClick={()=> onClickEdit(params)}><EditIcon/></IconButton>
                <IconButton variant="outlined" color="error" size="small" onClick={()=> onDelete(params)}><DeleteIcon/></IconButton>
              </Stack>
            );
        },
      }
    ];
    const AddNew  = (event)=> {
      event.preventDefault();

      axios.post(api_uri+"/surveyadd", {            
            
        title:event.target.title.value,
        description:event.target.description.value,
        enddate:event.target.enddate.value,
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          // return response.json();
          call_fetch()
          setOpenadd(false)
          toast.success("Record Added !", {position: "top-right",theme: "colored"});
        })
        .catch((error) => {
            swal("", "Failed to add", "error",{ button: "Try Again!",});
        });
      
    }
    const updaterecord  = (event)=> {
      event.preventDefault();

      axios.post(api_uri+"/surveyupdate", {            
        id:event.target.id.value,     
        title:event.target.title.value,
        description:event.target.description.value,
        enddate:event.target.enddate.value,
        sort:event.target.sort.value
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          call_fetch()
          setOpenedit(false)
          toast.success("Updated !", {position: "top-right",theme: "colored"});


        })
        .catch((error) => {
            swal("", "Failed to update", "error",{ button: "Try Again!",});
        });
      
    }
    const handleDeleteall = () => {
       
      const listid =selectedRows.join(',')
      if(listid){
        swal({
          title: "Are you sure you want to delete?",
          text: "",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {           
                  
            axios.post(api_uri+"/surveydeleteitems", {            
              ids:listid
          
              },{
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
              }
              })
              .then((response) => {
                call_fetch()
                if(selectedRows.length >1){
                  swal("Records Deleted!", "", "success",{ button: "Close",});
                }else{
                  swal("Record Deleted!", "", "success",{ button: "Close",});
                }
      
      
              })
              .catch((error) => {
                  swal("", "Failed to delete selected records!", "error",{ button: "Try Again!",});
              });
          } else {
            
          }
        });
      }else{
        swal("Please select records", "",{ button: "OK",});
      }
      

    }
    
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
    return (
      <Box p="20px" sx={wider}>
        <Header
          title="Survey Form List" 
          subtitle="Dashboard / Surveys"
        />
        <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
            <Button onClick={handleOpenadd} color="primary" variant="contained" startIcon={<AddIcon />}>
              Add
            </Button>
            <Button onClick={handleDeleteall} variant="contained" color="error" startIcon={<DeleteIcon />}>
              Delete Items
            </Button>
            
          </Stack>
        <Box
          m="20px 0 0 0"
          className="bg-white"
          
          
        >
          <DataGrid checkboxSelection disableRowSelectionOnClick
            rows={datalist}
            columns={columns}
            pageSizeOptions={[10, 25,50,100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(ids) => {
                  
              setSelectedRows(ids);
            }}
          />
        </Box>
        <Modal
            open={openadd}
            onClose={handleCloseadd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Add New Survey</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseadd}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={AddNew}>
                <div>
                  <div className='group-control'>
                    <label className="clabel">Survey Title <span className='req-label'>*</span>:</label>
                    <input type="text" className="form-control" name="title" id="title" placeholder='Enter Survey Title' required/>
                  </div>
                  
                  <div className='group-control'>
                    <label className="clabel">Short Description :</label>
                    <textarea className="form-control" name="description" id="description"placeholder='Description' ></textarea>
                  </div>
                  <div className='group-control'>
                    <label className="clabel">End Date :</label>
                    <input type="date" className="form-control" name="enddate" id="enddate" placeholder='' required/>
                  </div>
                  
                  <div className='bottom-control'>
                    <Button type="submit" variant='contained' color='success'>Save</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>

          <Modal
            open={openedit}
            onClose={handleCloseedit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Edit Survey Details</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseedit}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={updaterecord}>
                <div>
                  <div className='group-control'>
                    <label className="clabel">Survey Title <span className='req-label'>*</span>:</label>
                    <input type="text" className="form-control" name="title" id="title" placeholder='Enter Survey Title' defaultValue={editdata.title} required/>
                  </div>
                  
                  <div className='group-control'>
                    <label className="clabel">Short Description :</label>
                    <textarea className="form-control" name="description" id="description"placeholder='Description' >{editdata.description}</textarea>
                  </div>
                  <div className='group-control'>
                    <label className="clabel">End Date :</label>
                    <input type="date" className="form-control" name="enddate" id="enddate" placeholder='' defaultValue={dayjs(editdata.enddate).format('YYYY-MM-DD')} required/>
                  </div>
                  
                  <div className='group-control'>
                    <label className="clabel">Sorting  :</label>
                    <input type="number" className="form-control" name="sort" id="sort" placeholder='0' defaultValue={editdata.sort}/>
                  </div>
                  
                  <div className='bottom-control'>
                    <input type='hidden' name="id" id="id" defaultValue={editdata.id}/>
                    <Button type="submit" variant='contained' color='secondary'>Update</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
          <ToastContainer autoClose={1000}/>
      </Box>
    );
  };

export default Surveys



