import React from 'react'

import { Link } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";

const api_uri = process.env.REACT_APP_API_URL

const Forgot = () => {

    const handleForgot = (event) => {
        event.preventDefault();
        
      const email=event.target.email.value
    
        axios.post(api_uri+"/reset", {            
                
          email:email,
      
          },{
            headers:{
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
          }
          })
          .then((response) => {
  
              
                swal( "","Password reset link sent to your email ", "success",{ button: "Ok",});
              
          })
          .catch((error) => {
              swal("Network failed login your account", " ", "error",{ button: "Try Again!",});
          });
      };

  return (
    <div>
      
      <div className='backimage' style={{backgroundImage:`url(./lobg.png)`}}>

      <div className='map-bdy'>
				<div className='inner-map-bdy' style={{width:700}}>
          
					
				<div id="sign-in" className="auth-form   form-validation ">
        <h3 className='map-head'>Forgot Password</h3>
									<form onSubmit={handleForgot}  className="form-validate">
										
                    <div className="form-group mb-5">
                      <label className="mb-2 auth-labels"  htmlFor="val-email">Email</label>
                      <div>
                        <input type="email" className="form-control" autoComplete='off' name="email" id="email" required placeholder="Type Your Email Address"
                        />
                      </div>
                    </div>
										<div className="text-center form-group mb-3">
											
											<button type="submit" className=" btn-sign  ">
												Submit
											</button>
											
										</div>
									</form>
									<div className="sign-account mt-3">
										<p>Go back to <Link className="text-sign" to="/login">Login</Link></p>
									</div>
								</div>
				</div>
			</div>


      </div>
      
    </div>
  )
}

export default Forgot
