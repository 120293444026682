import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const Topbar = () => {

  return (
    <> 
    <Box className="headbar-box">
      
        <Link to={'/'}  onClick={ (event) => event.preventDefault() } ><img src={`/cos.jpeg`} className="left-logo" alt="logo"/></Link>
        <Link to={'/'} onClick={ (event) => event.preventDefault() }><img src={`/pgcounty.png`} className="right-logo" alt="logo"/></Link>
    </Box>
    {/* <div className="logo-bottom"></div> */}
    </>
  );
};

export default Topbar;
