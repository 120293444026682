import React, { useLayoutEffect, useState } from 'react'
import { Box, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import './admin.css'
import swal from "sweetalert";
import axios from "axios";
// import downfile "./sample_projects_csv_format.csv"
// import DownloadingIcon from '@mui/icons-material/Downloading';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
// import AddIcon from '@mui/icons-material/Add';

import Button from '@mui/material/Button';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const api_uri = process.env.REACT_APP_API_URL

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'white',
    boxShadow: 24,
    pl: 4,
    pr: 4,
    pb:4,
  };

const wider ={
  width : '99.9%',
}

function Projects() {
    const [Vendorlist, SetVendordata] = useState([])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const downFile = (url) => {
      const fileName = url.split("/").pop()
      const aTag  = document.createElement("a")
      aTag.href=url
      aTag.setAttribute("download", fileName)
      document.body.appendChild(aTag)
      aTag.click()
      aTag.remove()
    }
    const call_fetch = () =>{
      fetch(api_uri+"/projectlist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                SetVendordata(res)           
            }).catch((error) => {
                
            });
    }
    
    
    const onDelete = (e) => {
      const id = e.row.id;
      swal({
        title: "Are you sure you want to delete?",
        text: "",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) { 
              axios.get(api_uri+"/projectdelete/"+id, {
                  headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
                })
                .then((response) => {
                  call_fetch()
                  toast.success("Record has been deleted !", {position: "top-right",theme: "colored"});
                })
                .catch((error) => {
                    swal("", "Fail to delete the record", "error",{ button: "Try Again!",});
                });
          }
        })
    };
    const onClickEdit = (e) => {
      const currentRow = e.row;
      // return alert(JSON.stringify(currentRow, null, 4));
    };

    useLayoutEffect(() => {    
      
      
        call_fetch()
        
    },[]);
    
    
    const columns = [
      // { field: "id", headerName: "ID",headerClassName: 'super-app-theme--header', flex: 0.5 },
      { field: "project_name", headerName: "Project Name", headerClassName: 'super-app-theme--header', width: 350 },
      { field: "project_period", headerName: "Project Period", headerClassName: 'super-app-theme--header',width: 250  },
      { field: "project_value", headerName: "Project Value" , headerClassName: 'super-app-theme--header', width: 250},
      {
        field: 'action',
        headerName: 'Action',
        headerAlign:'center',
        align:'center',
        
        width: 250,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            
            
            return (
              <Stack direction="row" spacing={2} justifyItems={'center'}>
                <IconButton variant="outlined" color="warning" size="small" onClick={()=> onClickEdit(params)}><EditIcon/></IconButton>
                <IconButton variant="outlined" color="error" size="small" onClick={()=> onDelete(params)}><DeleteIcon/></IconButton>
              </Stack>
            );
        },
      }
      
    ];
    const [file, setFile] =useState()
      const handleFile = (event) =>{
        setFile(event.target.files[0])
      }
      const handleUpload  = (event)=> {
        event.preventDefault();
        const formData = new FormData()
        formData.append('file',file)
        fetch(api_uri+'/projectupload',{
          method :'POST',
          body:formData
        }).then((response) => response.json())
        .then((res) => {
          handleClose()
          call_fetch()
          swal("Success", "Records imported successfully", "success");
          

        })
        .catch((error)=> {
          console.log("Error",error)
          swal("Oops", "Fail to upload", "error",{ button: "Try Again!",});
        })
      }
      const handleDeleteall = () => {
       
        const listid =selectedRows.join(',')
        if(listid){
          swal({
            title: "Are you sure you want to delete?",
            text: "",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {           
                    
              axios.post(api_uri+"/projectdeleteitems", {            
                ids:listid
            
                },{
                  headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
                })
                .then((response) => {
                  call_fetch()
                  swal("Deleted records!", "", "success",{ button: "Close",});
        
        
                })
                .catch((error) => {
                    swal("", "Failed to delete selected records!", "error",{ button: "Try Again!",});
                });
            } else {
              
            }
          });
        }else{
          swal("Please select records", "",{ button: "OK",});
        }
        
  
      }
      const [selectedRows, setSelectedRows] = React.useState([]);
      const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
      });
    
    return (
      <Box p="20px" 
      sx={wider}>
        <Header
          title="PROJECTS"
          subtitle="List of Projects for Future Reference"
        />
        
        <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
            
            {/* <Button color="primary" variant="contained" startIcon={<AddIcon />}>
              Add
            </Button> */}
            <Button onClick={handleDeleteall} variant="contained" color="error" startIcon={<DeleteIcon />}>
              Delete Items
            </Button>
            
            <Button onClick={handleOpen} color="secondary" variant="contained" startIcon={<UploadFileIcon />}> Upload Projects List</Button>
          </Stack>
        <Box
          m="20px 0 0 0"
          className="bg-white"
        >
          
          <DataGrid checkboxSelection disableRowSelectionOnClick
            rows={Vendorlist}
            columns={columns}
            pageSizeOptions={[10, 25,50,100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(ids) => {
    
              setSelectedRows(ids);
            }}
          />
        </Box>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        
        <Box sx={style}>
          <div  style={{display:'flex',justifyContent:'space-between'}}>
          <h3>Upload your File here:</h3>
          <Tooltip title="Close">
            <IconButton color="#000" className='modal-close'  onClick={handleClose}>
                <CloseIcon/>
                </IconButton>
          </Tooltip>
          </div>
          
          <form onSubmit={handleUpload} style={{border: '1px dashed #1976d2',padding:'40px',display:'flex',justifyContent:'space-between',margin:'20px'}}>
            <input type="file" name="file" onChange={handleFile} required accept='.csv'/>
            <Button type='submit' variant="contained" startIcon={<UploadFileIcon />}>Upload</Button>
          </form>
          <Button style={{marginTop:'20px'}} color='warning' endIcon={<FileDownloadIcon />} variant="contained" className="" id="modal-modal-description" sx={{ mt: 2 }} onClick={()=>{downFile('/sample_project_csv_format.csv')}}>
              Download Your sample projects file here
          </Button>
          
        </Box>
        
      </Modal>
      
      <ToastContainer autoClose={1000}/>
      </Box>
    );
  };

export default Projects



