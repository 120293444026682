import React, {  useLayoutEffect, useState } from 'react'
import { Box, Grid,Card,CardContent } from "@mui/material";
import Header from "./Header";


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import DBarChart from './charts/DBarChart';
import dayjs from 'dayjs';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

import GroupsIcon from '@mui/icons-material/Groups';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

// import LinearProgress from '@mui/material';

import { useNavigate } from 'react-router-dom';

const genhsl = () => {
  const ehsl =["#dae162","#dae162", "#fb7470","#e0c39d","#26d9b5","#d562d5","#59c5e1","#8d6e63","#ba68c8","#91cc75","#ee6666","#fac858","#5470c6","#73c0de","#cddc39","#ffc107","#405180","#F7C4AF","#D098AF","#B184B1","#8469A9","#D5EE06","#F83699","#F68E00","#672BAF","#FFEA0E","#0087F9","#647F57","#E88269","#4C85BD","#EAE0D0","#D7B27B"] 
  
  let pick = Math.floor(Math.random() * 31 + 0);
  return ehsl[pick];

}

const api_uri = process.env.REACT_APP_API_URL



function Dashboard() {
  const [summary, setSummary] = useState([])
  const [recent, setrecent] = useState([])
  const [surveyid, setsurveyid] = useState("")
  const [showdash, setshowdash] = useState(false)
  const navigate = useNavigate();

  
  const [surveylist, setsurveylist ]=useState([])
  const [recentsurvey, setrecentsurvey] = useState([])
  const [datewise, setdatewise] = useState([])
  const call_fetch = () =>{
    fetch(api_uri+"/surveylist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
              setsurveylist(res) 
              if(res.length > 0){
                setsurveyid(res[(res.length-1)].id)
                console.log(res[(res.length-1)].id)
                let surveyid=res[(res.length-1)].id
                
                setshowdash(true)

                fetch(api_uri+"/sursummary/"+surveyid)
                      .then((response) => {
                          return response.json();
                      })
                      .then((res) => {
                          setSummary(res)         
                      }).catch((error) => {
                          
                      });
                fetch(api_uri+"/recentcomplete/"+surveyid)
                      .then((response) => {
                          return response.json();
                      })
                      .then((res) => {
                          setrecent(res)         
                      }).catch((error) => {
                          
                      });
                fetch(api_uri+"/datewisereport/"+surveyid)
                      .then((response) => {
                          return response.json();
                      })
                      .then((res) => {
                        let barData = []
                        res.map((cho,index)=>  {
                          var label =dayjs(cho.sdate).format('MM/DD/YYYY')
                          
                  
                          const data={
                            labels: label,
                            Responses:cho.respo,
                            color:  genhsl()
                          } 
                          
                          barData.push(data)   
                  
                        })
                        console.log(barData)
                          setdatewise(barData)         
                      }).catch((error) => {
                          
                      });
              }          
            }).catch((error) => {
                
            });
    
  }
 
    
    useLayoutEffect(() => {

      call_fetch()
  },[]);

  const recentbody = recent.map((rec,index)=>
    <tr key={index}>
      <td className='text-center th-width1'>{index+1}</td>
      <td className='th-width2'>{rec.email}</td>
      <td className='text-center th-width3'>{dayjs(rec.CreatedDt).format('MM/DD/YYYY')}</td>
    </tr>
  )
  const onChoose=(e)=>{
    // console.log(e.target.value)
    let surveyid=e.target.value
    setsurveyid(e.target.value)
    
    setshowdash(true)

    fetch(api_uri+"/sursummary/"+surveyid)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
              setSummary(res)         
          }).catch((error) => {
              
          });
    fetch(api_uri+"/recentcomplete/"+surveyid)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
              setrecent(res)         
          }).catch((error) => {
              
          });
    fetch(api_uri+"/datewisereport/"+surveyid)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
            let barData = []
            res.map((cho,index)=>  {
              var label =dayjs(cho.sdate).format('DD/MM/YYYY')
              
      
              const data={
                labels: label,
                Responses:cho.respo,
                color:  genhsl()
              } 
              
              barData.push(data)   
      
            })
            console.log(barData)
              setdatewise(barData)         
          }).catch((error) => {
              
          });

  }
  const opendashTS = ()=>{

    navigate('/admin/survey-dash?ty=ts&survey='+surveyid);

  }
  const opendashARC = ()=>{

    navigate('/admin/survey-dash?ty=arc&survey='+surveyid);

  }
  const opendashAPR = ()=>{

    navigate('/admin/survey-dash?ty=apr&survey='+surveyid);

  }
  const opendashARS = ()=>{

    navigate('/admin/survey-dash?ty=ars&survey='+surveyid);

  }
  const opendashTRS = ()=>{

    navigate('/admin/survey-dash?ty=trs&survey='+surveyid);

  }

  const options = surveylist.map((obj)=>
        <MenuItem key={obj.id}  value={obj.id}><strong>{obj.title}</strong></MenuItem>
      );
    return (
      <Box p="20px" >
        <Header
          title="Dashboard"
          subtitle=""
        />
        
        <Grid container spacing={2}>
          
          <Grid item xs={12} md={6}>
            <div className='selectsurvey'>Select Survey :</div>
            <FormControl sx={{ m: 1, width: '100%' }} className='sel-sur-dash'>
              <Select 
              value={surveyid}

                onChange={onChoose}
                displayEmpty
                
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {options}
              </Select>
            </FormControl>
          </Grid>
         
          
        </Grid>
        {showdash?
        <div>
        <Grid container spacing={2} marginTop={4} justifyContent={'center'}>
          
          <Grid item xs={12} md={4}>
              <div className='widget-stat cards bg-primary' onClick={opendashTS}>
                <div className="card-body ">
                  <div className="media">
                    <span className="me-3">
                      <LibraryBooksIcon/>
                    </span>
                    <div className="media-body text-white">
                      <p className="mb-1">Total Surveys Sent</p>
                      <h3 className="text-white h3">{summary.sent}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className='widget-stat cards bg-info' onClick={opendashARC}>
                <div className="card-body ">
                  <div className="media">
                    <span className="me-3">
                      <MarkEmailReadIcon/>
                    </span>
                    <div className="media-body text-white">
                      <p className="mb-1">Aggregate Number of Survey Responses Collected from Sent Emails</p>
                      <h3 className="text-white h3">{summary.attend}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className='widget-stat cards bg-warning' onClick={opendashAPR}>
                <div className="card-body ">
                  <div className="media">
                    <span className="me-3">
                      <GroupsIcon/>
                    </span>
                    <div className="media-body text-white">
                      <p className="mb-1">Aggregate of Surveys Awaiting Responses from Sent Emails</p>
                      <h3 className="text-white h3">{summary.pending }</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <div className='widget-stat cards bg-sent' onClick={opendashARS}>
                <div className="card-body ">
                  <div className="media">
                    <span className="me-3">
                      <BookmarkAddedIcon/>
                    </span>
                    <div className="media-body text-white">
                      <p className="mb-1">Aggregate Number of Responses Collected via Survey Link</p>
                      <h3 className="text-white h3">{summary.submitted-summary.attend}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <div className='widget-stat cards bg-secondary' onClick={opendashTRS}>
                <div className="card-body ">
                  <div className="media">
                    <span className="me-3">
                      <FactCheckIcon/>
                    </span>
                    <div className="media-body text-white">
                      <p className="mb-1">Total Number of Survey Responses Collected</p>
                      <h3 className="text-white h3">{summary.submitted}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={4}>
          <Grid item xs={12} md={6}>
              <div className='cards'>
                <div className="card-header">
                    <h4 className="card-title">Recently Completed Surveys</h4>
                </div> 
                <div className='card-body pt-2'>
                  <div className='table-responsive recentOrderTable'>
                    <table className='table verticle-middle text-nowrap table-responsive-md'>
                      <thead>
                        <tr>
                          <th  className='th-width1 text-center'>No.</th>
                          <th className='th-width2'>Vendor</th>
                          <th  className='text-center th-width3'>Response Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recent.length > 0?recentbody:<tr><td className='text-center' colSpan={3}>No responses</td></tr>}
                        
                      </tbody>

                    </table>
                  </div>
                </div>

              </div>
          </Grid>
          <Grid item xs={12} md={6}>

          <div className='cards'>
                <div className="card-header">
                    <h4 className="card-title">Datewise Survey Responses</h4>
                </div> 
                <div className='card-body pt-2'>
                  <Box height="50vh"  >
                    {datewise.length > 0?
                  <DBarChart  data={datewise} layout={"vertical"}/>
                  :<div className='text-center pt-4'>No responses</div>}
                  </Box> 
                </div>

              </div>
            
          </Grid>
          
        </Grid>
      </div>:''

        }
        

        

      </Box>
    );
  };

export default Dashboard


