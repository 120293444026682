import React, { useLayoutEffect, useState } from 'react'
import { Box, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import './admin.css'
import swal from "sweetalert";
import axios from "axios";
// import downfile "./sample_vendors_csv_format.csv"
// import DownloadingIcon from '@mui/icons-material/Downloading';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const api_uri = process.env.REACT_APP_API_URL

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'white',
    boxShadow: 24,
    pl: 4,
    pr: 4,
    pb:4,
  };
const styleadd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'white',
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb:4,
};

const wider ={
  width : '99.9%',
}
const widergrid ={
  width : '99.9%',
}

function Vendors() {
    const [sel_temp, setsel_temp] = useState('')
    const [Vendorlist, SetVendordata] = useState([])
    const [surveylist, setsurveylist ]=useState([])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [opensend, setOpensend] = React.useState(false);
    const handleOpensend = () => setOpensend(true);
    const handleClosesend = () => setOpensend(false);
    
    const [openadd, setOpenadd] = React.useState(false);
    const handleOpenadd = () => setOpenadd(true);
    const handleCloseadd = () => setOpenadd(false);

    
    const [openedit, setOpenedit] = React.useState(false);
    const handleOpenedit = () => setOpenedit(true);
    const handleCloseedit = () => setOpenedit(false);
    const [editdata, seteditdata] = useState([])


    const downFile = (url) => {
      const fileName = url.split("/").pop()
      const aTag  = document.createElement("a")
      aTag.href=url
      aTag.setAttribute("download", fileName)
      document.body.appendChild(aTag)
      aTag.click()
      aTag.remove()
    }
    const call_fetch = () =>{
      fetch(api_uri+"/vendorlist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                SetVendordata(res)           
            }).catch((error) => {
                
            });
    }
    const call_fetch_survey= () =>{
      fetch(api_uri+"/emailtemplatelist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setsurveylist(res)           
            }).catch((error) => {
                
            });
    }
    
    const onDelete = (e) => {
      const id = e.row.id;
      swal({
        title: "Are you sure you want to delete this vendor?",
        text: "",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) { 
            axios.get(api_uri+"/vendordelete/"+id, {
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
              }
              })
              .then((response) => {
                call_fetch()
                toast.success("Record has been deleted !", {position: "top-right",theme: "colored"});
              })
              .catch((error) => {
                  swal("", "Fail to delete the record", "error",{ button: "Try Again!",});
              });
        }
      })
    };
    const onClickEdit = (e) => {
      seteditdata(e.row)
      handleOpenedit()
    };

    useLayoutEffect(() => {    
      
      
        call_fetch()
        
        call_fetch_survey()
        
    },[]);
    
    
    const columns = [
      // { field: "id", headerName: "ID",headerClassName: 'super-app-theme--header', flex: 0.5 },
      { field: "business_email", headerName: "Business Email", headerClassName: 'super-app-theme--header',width: 300  },
      { field: "business_name", headerName: "Business Name", headerClassName: 'super-app-theme--header' },
      { field: "business_address", headerName: "Business Address" ,headerClassName: 'super-app-theme--header',width: 180 },
      { field: "fname", headerName: "First Name",headerClassName: 'super-app-theme--header' },
      { field: "lname", headerName: "Last Name",headerClassName: 'super-app-theme--header' },
      { field: "contact_no", headerName: "Contact Number",headerClassName: 'super-app-theme--header',width: 150  },
      { field: "speciality_area", headerName: "Speciality Area",headerClassName: 'super-app-theme--header' },
      {
        field: 'action',
        headerName: 'Action',
        headerAlign:'center',
        align:'center',
        type: 'actions',
        
        width: 150,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            
            
            return (
              <Stack direction="row" spacing={2} justifyItems={'center'}>
                <Tooltip title="Edit"><IconButton variant="outlined" color="warning" size="small" onClick={()=> onClickEdit(params)}><EditIcon/></IconButton></Tooltip>
                <Tooltip title="Delete"><IconButton variant="outlined" color="error" size="small" onClick={()=> onDelete(params)}><DeleteIcon/></IconButton></Tooltip>
              </Stack>
            );
        },
      }
      
    ];
    const [file, setFile] =useState()
      const handleFile = (event) =>{
        setFile(event.target.files[0])
      }
      const handleUpload  = (event)=> {
        event.preventDefault();
        const formData = new FormData()
        formData.append('file',file)
        fetch(api_uri+'/vendorupload',{
          method :'POST',
          body:formData
        }).then((response) => response.json())
        .then((res) => {
          handleClose()
          call_fetch()
          swal("Success", "Records imported successfully", "success");
          

        })
        .catch((error)=> {
          console.log("Error",error)
          swal("Oops", "Fail to upload", "error",{ button: "Try Again!",});
        })
      }
      const AddNew  = (event)=> {
        event.preventDefault();
  
        axios.post(api_uri+"/vendoradd", {            
              
          business_email:event.target.business_email.value,
          business_name:event.target.business_name.value,
          business_address:event.target.business_address.value, 
          fname:event.target.fname.value, 
          lname:event.target.lname.value, 
          contact_no:event.target.contact_no.value, 
          speciality_area:event.target.speciality_area.value
      
          },{
            headers:{
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
          }
          })
          .then((response) => {
            // return response.json();
            call_fetch()
            setOpenadd(false)
            toast.success("Record Added !", {position: "top-right",theme: "colored"});
  
  
          })
          .catch((error) => {
              swal("", "Failed to add", "error",{ button: "Try Again!",});
          });
        
      }
      const updaterecord  = (event)=> {
        event.preventDefault();
  
        axios.post(api_uri+"/vendorupdate", {            
          id:event.target.id.value,   
          business_email:event.target.business_email.value,
          business_name:event.target.business_name.value,
          business_address:event.target.business_address.value, 
          fname:event.target.fname.value, 
          lname:event.target.lname.value, 
          contact_no:event.target.contact_no.value, 
          speciality_area:event.target.speciality_area.value
      
          },{
            headers:{
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
          }
          })
          .then((response) => {
            call_fetch()
            setOpenedit(false)
            toast.success("Updated !", {position: "top-right",theme: "colored"});
  
  
          })
          .catch((error) => {
              swal("", "Failed to update", "error",{ button: "Try Again!",});
          });
        
      }
      const handleDeleteall = () => {
       
        const listid =selectedRows.join(',')
        if(listid){
          swal({
            title: "Are you sure you want to delete?",
            text: "",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {           
                    
              axios.post(api_uri+"/vendordeleteitems", {            
                ids:listid
            
                },{
                  headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
                })
                .then((response) => {
                  call_fetch()
                  if(selectedRows.length >1){
                    swal("Records Deleted!", "", "success",{ button: "Close",});
                  }else{
                    swal("Record Deleted!", "", "success",{ button: "Close",});
                  }
                  
        
        
                })
                .catch((error) => {
                    swal("", "Failed to delete selected records!", "error",{ button: "Try Again!",});
                });
            } else {
              
            }
          });
        }else{
          swal("Please select records", "",{ button: "OK",});
        }
        

      }
      const handleSendmail = () => {
        const listid =selectedRows.join(',')
        if(listid){
          handleOpensend()
        }else{
          swal("Please select records", "",{ button: "OK",});
        }
        
      }
      const onChoose=(e)=>{
        const listid =selectedRows.join(',')
        setsel_temp(e.target.value)
        swal("Are you sure you want to send?","", {
          buttons: {
            cancel: "Cancel",
            confirm: true,
          },
        })
        .then((value) => {
          if(value===true){
       
          axios.post(api_uri+"/sendinvite", {            
            vendors:listid,
            id:e.target.value,
            count:selectedRows.length,        

        
            },{
              headers:{
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
            }
            })
            .then((response) => {
              toast.success("Invites sent!!", {position: "top-right",theme: "colored"});
              
    
    
            })
            .catch((error) => {
                swal("", "Failed to send invites!", "error",{ button: "Try Again!",});
            });
          }
          setsel_temp("")
          handleClosesend()
        });

      }

      const options = surveylist.map((obj)=>
        <MenuItem key={obj.id}  value={obj.id}>{obj.template_name}</MenuItem>
      );
      const [selectedRows, setSelectedRows] = React.useState([]);
      const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
      });
    
    return (
      <Box p="20px" 
      sx={wider}>
        <Header
          title="Vendors List"
          subtitle="Dashboard / Vendors"
        />
        
        <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
            
            <Button onClick={handleOpenadd} color="primary" variant="contained" startIcon={<AddIcon />}>
              Add New Vendor
            </Button>
            <Button onClick={handleDeleteall} variant="contained" color="error" startIcon={<DeleteIcon />}>
              Delete Items
            </Button>
            
            <Button onClick={handleSendmail} variant="contained" color="success" startIcon={<ForwardToInboxIcon />}>
              Send Invite
            </Button>
            <Button onClick={handleOpen} color="secondary" variant="contained" startIcon={<UploadFileIcon />}> Upload Vendors List</Button>
          </Stack>
        <Box
          m="20px 0 0 0"
          className="bg-white"
          sx={widergrid}
        >
          
          <DataGrid checkboxSelection disableRowSelectionOnClick
            rows={Vendorlist}
            columns={columns}
            
            initialState={{ pinnedColumns: {  right: ['action'] } }}
            pageSizeOptions={[10, 25,50,100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(ids) => {             
    
              setSelectedRows(ids);
            }}
          />
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          
          
          <Box sx={style}>
            <div  style={{display:'flex',justifyContent:'space-between'}}>
            <h3>Upload your File here:</h3>
            <Tooltip title="Close">
              <IconButton color="#000" className='modal-close'  onClick={handleClose}>
                  <CloseIcon/>
                  </IconButton>
            </Tooltip>
            </div>
            
            <form onSubmit={handleUpload} style={{border: '1px dashed #1976d2',padding:'40px',display:'flex',justifyContent:'space-between',margin:'20px'}}>
              <input type="file" name="file" onChange={handleFile} required accept='.csv'/>
              <Button type='submit' variant="contained" startIcon={<UploadFileIcon />}>Upload</Button>
            </form>
            <Button style={{marginTop:'20px'}} color='warning' endIcon={<FileDownloadIcon />} variant="contained" className="" id="modal-modal-description" sx={{ mt: 2 }} onClick={()=>{downFile('/sample_vendors_csv_format.csv')}}>
                Download Your sample vendors file here
            </Button>
            
          </Box>
          
        </Modal>

      
          <Modal
            open={openadd}
            onClose={handleCloseadd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Add New Vendor</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseadd}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={AddNew}>
                <div>
                  <Grid container spacing={2} >
                      <Grid item xs={12} md={6}>
                        <div className='group-control'>
                          <label className="clabel">Business Email <span className='req-label'>*</span>:</label>
                          <input type="text" className="form-control" name="business_email" id="business_email" required/>
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Business Name :</label>
                          <input type="text" className="form-control" name="business_name" id="business_name" />
                        </div>
                        
                        <div className='group-control'>
                          <label className="clabel">Business Address :</label>
                          <input type="text" className="form-control" name="business_address" id="business_address" />
                        </div>
                        
                        
                        <div className='group-control'>
                          <label className="clabel">Speciality Area :</label>
                          <input type="text" className="form-control" name="speciality_area" id="speciality_area" />
                        </div>

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className='group-control'>
                          <label className="clabel">First Name :</label>
                          <input type="text" className="form-control" name="fname" id="fname" />
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Last Name :</label>
                          <input type="text" className="form-control" name="lname" id="lname" />
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Contact Number :</label>
                          <input type="text" className="form-control" name="contact_no" id="contact_no" />
                        </div>
                      </Grid>
                  </Grid>
                  
                  <div className='bottom-control'>
                    <Button type="submit" variant='contained' color='success'>Save</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
          
          <Modal
            open={openedit}
            onClose={handleCloseedit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Edit Vendor</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseedit}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={updaterecord}>
                <div>
                  <Grid container spacing={2} >
                      <Grid item xs={12} md={6}>
                        <div className='group-control'>
                          <label className="clabel">Business Email <span className='req-label'>*</span>:</label>
                          <input type="text" className="form-control" name="business_email" id="business_email" defaultValue={editdata.business_email} required/>
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Business Name :</label>
                          <input type="text" className="form-control" name="business_name" id="business_name" defaultValue={editdata.business_name}/>
                        </div>
                        
                        <div className='group-control'>
                          <label className="clabel">Business Address :</label>
                          <input type="text" className="form-control" name="business_address" id="business_address" defaultValue={editdata.business_address}/>
                        </div>
                        
                        
                        <div className='group-control'>
                          <label className="clabel">Speciality Area :</label>
                          <input type="text" className="form-control" name="speciality_area" id="speciality_area" defaultValue={editdata.speciality_area}/>
                        </div>

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className='group-control'>
                          <label className="clabel">First Name :</label>
                          <input type="text" className="form-control" name="fname" id="fname" defaultValue={editdata.fname}/>
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Last Name :</label>
                          <input type="text" className="form-control" name="lname" id="lname" defaultValue={editdata.lname}/>
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Contact Number :</label>
                          <input type="text" className="form-control" name="contact_no" id="contact_no" defaultValue={editdata.contact_no} />
                        </div>
                      </Grid>
                  </Grid>
                  
                  <div className='bottom-control'>
                    <input type='hidden' name="id" id="id" defaultValue={editdata.id}/>
                    <Button type="submit" variant='contained' color='secondary'>Update</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
          <Modal
        open={opensend}
        onClose={handleClosesend}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        
        <Box sx={style}>
          <div  style={{display:'flex',justifyContent:'space-between'}}>
          <h3>Select Email Template</h3>
          <Tooltip title="Close">
            <IconButton color="#000" className='modal-close'  onClick={handleClosesend}>
                <CloseIcon/>
                </IconButton>
          </Tooltip>
          </div>
          <FormControl sx={{ m: 1, width: '100%' }}>
            <Select              
              onChange={onChoose}
              value={sel_temp}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              
              {options}
            </Select>
          </FormControl>
          
          <div>

          </div>
          
        </Box>
        
      </Modal>
          <ToastContainer autoClose={1000}/>
      </Box>
    );
  };

export default Vendors



