import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const api_uri = process.env.REACT_APP_API_URL
function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + (20*60*1000),
  );
  
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}



function Index() {
  const navigate = useNavigate();

  useEffect(() => { 
        
      const tokenDetailsString = localStorage.getItem('userDetails');
      if(tokenDetailsString){
        let tokenDetails = JSON.parse(tokenDetailsString);
        if(tokenDetails.expireDate!==null){
        
            let expireDate = new Date(tokenDetails.expireDate);
            let todaysDate = new Date();         

            if (todaysDate < expireDate){            
              navigate('/admin', {replace:true});
            }

        }
        
          
      }
  
  },[]);



  

    const handleLogin = (event) => {
      event.preventDefault();
      
    const email=event.target.email.value
    const password=event.target.password.value
  
      axios.post(api_uri+"/login", {            
              
        email:email,
        password:password,
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {

            console.log(response.data);
            if(response.data.message==="login"){
             
              saveTokenInLocalStorage(response.data);
              navigate('/admin');
            }else{
              swal( response.data.message,"", "error",{ button: "Try Again!",});
            }
            
        })
        .catch((error) => {
            swal("Network failed login your account", " ", "error",{ button: "Try Again!",});
        });
    };
    
    



  return (
    <div>
      
      <div className='backimage' style={{backgroundImage:`url(./lobg.png)`}}>

      <div className='map-bdy'>
				<div className='inner-map-bdy' style={{width:700}}>
          
					
				<div id="sign-in" className="auth-form   form-validation ">
        <h3 className='map-head'>Admin Login</h3>
									<form onSubmit={handleLogin}  className="form-validate">
										
                    <div className="form-group mb-5">
                      <label className="mb-2 auth-labels"  htmlFor="val-email">Email</label>
                      <div>
                        <input type="email" className="form-control" autoComplete='off' name="email" id="email" required placeholder="Type Your Email Address"
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 auth-labels">Password</label>
                      <input
                        type="password" autoComplete='off'
                        name="password" id="password"
                        className="form-control"
                        placeholder="Type Your Password"
                        required
                      />
                      
											{/* <p className=''><Link className=" forgot-labels " to="/forgot-password">Forgot Password?</Link></p> */}
										</div>
										<div className="text-center form-group mb-3">
											
											<button type="submit" className=" btn-sign  ">
												Sign In
											</button>
											
										</div>
									</form>
									<div className="sign-account mt-3">
										{/* <p>Don't have an account? <Link className="text-sign" to="/register">Sign up</Link></p> */}
									</div>
								</div>
				</div>
			</div>


      </div>
      
    </div>
  )
}

export default Index
