import React from 'react'
import { Box } from '@mui/material'

import { Link } from 'react-router-dom';

function Thank() {
  let formData =[]
  const tokenDetailsString = localStorage.getItem('formdata');
  if(tokenDetailsString){
    formData = JSON.parse(localStorage.getItem('formdata'));
   
  }


  return (
    <Box className="thank-box">
      
        <div style={{textAlign:'center'}}>
        <img src={`/thank.png`} className="thank-logo" alt="logo"/>
        <div className='thank-bottom'>Thank you for participating in our Vendor Procurement Participation Survey!</div>

        <div className='Links'>
        { formData['qes_19']?
                    
                        <div className='select-box-links'>
                          <label className='sub-label'> To begin the certification process:</label><br/>
                            <Link target='_blank' to={"https://www.princegeorgescountymd.gov/departments-offices/central-services/about-ocs/supplier-development-diversity"}> Supplier Development & Diversity | Prince George's County (princegeorgescountymd.gov)</Link>
                        </div>
                    :''
                }
            { formData['qes_23']?
                    ( formData['qes_23']==='3'?
                        <div className='select-box-links'>
                            <label className='sub-label'> Learn more about PGC Supply Schedule:</label><br/>
                            <Link target='_blank' to={"https://www.princegeorgescountymd.gov/departments-offices/central-services/prince-georges-county-supply-schedule"}>https://www.princegeorgescountymd.gov/departments-offices/central-services/prince-georges-county-supply-schedule</Link>
                        </div>
                    :""):''
                }
        </div>
        </div>
       
    </Box>
  )
}

export default Thank
