import { useState ,useRef, useLayoutEffect} from "react";
import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";


import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import swal from "sweetalert";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SaveIcon from '@mui/icons-material/Save';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

import Singlequestions from "./Singlequestions";

import axios from "axios";

const api_uri = process.env.REACT_APP_API_URL

const SurveyHome = () => {
    const navigate = useNavigate();

    // const queryParameters = new URLSearchParams(window.location.search)
    const slug ="vendor-enagement-survey"

    const bank = {
        elements:[
            {
                type:''
            },
        ],
    
      };
    const suveysblank ={
      title:''
    }
    
    const ref = useRef(null);
    const [qesData, setQesData] = useState(bank);
    const [singleData, setsingleData] = useState(suveysblank)
    const [formData, setFormData]= useState([])
    const [optarray, setoptarray]= useState([])

    const [showQestion, setshowQestion] = useState(1); 
    const [quesno, setquesno]=useState('');     
    const [questype, setquestype]=useState('');
    const [datalength, setdatalength] = useState(1);  
    const [showPrev, setshowPrev] = useState('none');   
    const [showNext, setshowNext] = useState('inline-flex');   
    const [showSave, setshowSave] = useState('inline-flex');   
    const [showSur, setshowSur] = useState('none');   
    const [showexp, setshowexp] = useState('none');   
    const [showFinish, setshowFinish] = useState('none'); 

    const call_fetch = () =>{
        fetch(api_uri+"/surveysingle/"+slug)
              .then((response) => {
                  return response.json();
              })
              .then((res) => {
                if(res.length > 0){
                  setshowSur('block')
                  setsingleData(res[0])   
                  if(res[0].questions){
                  const array =JSON.parse(res[0].questions)
                    setQesData(array)
                    setdatalength((array['elements'].length))
                  } 
                }else{
                  
                  setshowexp('block')
                  // alert("Survey has ended.")
                }
                       
              }).catch((error) => {
                  
              });
      }

    useLayoutEffect(() => {    
      
      
        call_fetch()
        
      },[]);
      const handleNextButton = () => {
        
        if(formData[quesno]){
          if(formData['qes_01']){
            let choosed = []
            if(formData['qes_01']==='1'){
              choosed = [ "qes_01","qes_02","qes_03","qes_04","qes_05","qes_06","qes_07","qes_08","qes_09","qes_10","qes_11","qes_12","qes_13","qes_16","qes_17","qes_18","qes_19","qes_20","qes_21","qes_23","qes_24"] 
             
            }else if(formData['qes_01']==='2'){
              choosed = ["qes_01","qes_02","qes_04","qes_09","qes_10","qes_11","qes_12","qes_13","qes_16","qes_17","qes_18","qes_19","qes_20","qes_21","qes_23","qes_24"]
            }else if(formData['qes_01']==='3'){
              choosed = ["qes_01","qes_14","qes_15","qes_16","qes_17","qes_18","qes_19","qes_20","qes_21","qes_23","qes_24"]
            }
            setoptarray(choosed)
            setdatalength(choosed.length)
            // handleNext()
          }
          if(quesno === 'qes_16'|| quesno === 'qes_22'){

            if(formData[quesno].length >=3 ){
              handleNext()
            }else{
              swal('Please select 3 response from the choice','','warning')
            }

          }
          else if(quesno === 'qes_18' || quesno === 'qes_21'){
            if(formData[quesno]==='1'){
              if(formData[quesno+'_1']){
  
                if(formData[quesno+'_1']==="Others"){
                  if(formData[quesno+'_2']){
                    handleNext()
                  }else{
                    swal('Please provide the public agency name','','warning')
                  }

                }else{
                  handleNext()
                }
              }else{
                swal('Please select a response','','warning')
              }
            }else{
              handleNext()
            }
          }
          else if(quesno === 'qes_17'){
            if(formData[quesno]==='14'){
              if(formData[quesno+'_1']){
                handleNext()
              }else{
                swal('Please provide information about your firm','','warning')
              }
            }else{
              handleNext()
            }
          }
          else{
            handleNext()
          }
          
          // alert(quesno)
          
        }
        else if(quesno==='qes_15'){
          swal('Please enter your response','','warning')
        }
        else if(quesno==='qes_12'){
          if(quesno==='qes_12' && formData['qes_12_1'] && formData['qes_12_2'] && formData['qes_12_3'] && formData['qes_12_4'] && formData['qes_12_5'] && formData['qes_12_6'] && formData['qes_12_7'] ){
            handleNext()
          }else{
            swal('Kindly provide answers to all of the questions.','','warning')
          }
        }
        
        else if(quesno==='qes_13'){
          if(quesno==='qes_13' && formData['qes_13_1'] && formData['qes_13_2'] && formData['qes_13_3'] && formData['qes_13_4'] && formData['qes_13_5'] && formData['qes_13_6'] && formData['qes_13_7'] && formData['qes_13_8'] && formData['qes_13_9']){
            handleNext()
          }else{
            swal('Kindly provide answers to all of the questions.','','warning')
          }
        }
        
        else if(quesno==='qes_14'){
          if(quesno==='qes_14' && formData['qes_14_1'] && formData['qes_14_2'] && formData['qes_14_3'] && formData['qes_14_4'] && formData['qes_14_5'] && formData['qes_14_6'] && formData['qes_14_7'] && formData['qes_14_8'] && formData['qes_14_9']){
            handleNext()
          }else{
            swal('Kindly provide answers to all of the questions.','','warning')
          }
        }       
        
        else{
          if(questype==="textarea" || questype==="email" || questype==="number"  || questype==="text" ){
            swal('Please enter your response','','warning')
          }else{
            swal('Please select a response','','warning')
          }
        }
        
      }
      const handleNext = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
        
          let i =showQestion
          let j=i+1
          setshowQestion(j)
          if(j===datalength){
            setshowNext('none')
            setshowFinish('inline-flex')
          }else{
            setshowNext('inline-flex')
            setshowFinish('none')
          }
          if(j===1){
            setshowPrev('none')
          }else{
            setshowPrev('inline-flex')
          }

      };
      const handlePrev = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
        let i =showQestion
        let j=i-1
        setshowQestion(j)
        if(j===datalength){
          setshowNext('none')
          setshowFinish('inline-flex')
        }else{
          setshowNext('inline-flex')
          setshowFinish('none')
        }
        if(j===1){
          setshowPrev('none')
        }else{
          setshowPrev('inline-flex')
        }
      };
      const handleSave = () => {
        console.log(formData)
        // axios.post(api_uri+"/save", {            
                
        //   data:formData
      
        //   },{
        //     headers:{
        //       'Access-Control-Allow-Origin': '*',
        //       'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        //   }
        //   })
        //   .then((response) => {
        //     call_fetch()
        //   })
        //   .catch((error) => {
        //       swal("Oops", "failed", "error",{ button: "Try Again!",});
        //   });
      };
      const handleSubmit = (event)=> {
        event.preventDefault();
        
         
        const email= event.target.email.value
        const BName= event.target.BName.value
        const FName= event.target.FName.value
        const LName= event.target.LName.value
        const BPhone= event.target.BPhone.value
        if(BPhone.length >= 7){
          axios.post(api_uri+"/submit", {            
                
            data:formData,
            email:email,
            business_name:BName,
            fname:FName,
            lname:LName,
            contact_no:BPhone,
            surveyid:singleData.id
        
            },{
              headers:{
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
            }
            })
            .then((response) => {
              setshowFinish('none')
              setshowNext('none')
              setshowPrev('none')
              setshowSave('none')
              let i =showQestion
              let j=i+1
              setshowQestion(j)
            })
            .catch((error) => {
                swal("Oops", "failed", "error",{ button: "Try Again!",});
            });
          
          console.log(formData)
          localStorage.setItem('formdata', JSON.stringify(formData));
          navigate('/thankyou-page', { 
            replace: true });

        }else{
          swal('Please enter valid phone number','','warning')
        }


        
      };
      
  return (
    <>
    <div style={{ display: showSur }}>
        <Box className='title-section'>
            <Link to={'/'} onClick={ (event) => event.preventDefault() }><img src={`/prince-white.png`} className="center-logo" alt="logo"/></Link>

            <div className="survey-title">{singleData.title}</div>
        </Box>
        <Container ref={ref}>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12}  className="csx1" style={{borderBottom:'2px solid #E0E0E0'}} >
                    <div className="survey-short">
                        Your answers to these questions will help us to better understand the experiences of organizations like yours. This information is anonymous and will not be used to identify respondents.
                    </div>
                </Grid>
            </Grid>
            <Box marginTop={4}  >
                <Grid container spacing={0} display="flex" justifyContent={'center'}>
                    <Grid item xs={12} md={10}   >
                    <div>
                      
                    <div className="progress">
                          <div>Step Progress: <span>{((showQestion-1)/datalength*100).toFixed(0)}%</span></div>
                          <progress className="progress1" value={showQestion-1} max={datalength}>  </progress>
                          </div>
                        <form onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                            <Question id={showQestion} formData={formData} setFormData={setFormData} qesData={qesData} datalength={datalength} optarray={optarray} setquesno={setquesno} setquestype={setquestype} />
                            <Stack direction="row" spacing={2} display="flex" marginTop={4} marginBottom={4} justifyContent={'flex-end'} >
                            <Button  style={{ display: showPrev,background:'#2C3175' }} className="user-btn pre" variant="contained" onClick={handlePrev} startIcon={<SkipPreviousIcon />}>
                                Prev
                            </Button>
                            
                            <Button   style={{ display: "none",background:'#26975A' }} className="user-btn save"  variant="contained"  onClick={handleSave} endIcon={<SaveIcon />}>
                                Save 
                            </Button>
                            <Button  style={{ display: showNext,background:'#2C3175' }} className="user-btn nxt"  variant="contained" onClick={handleNextButton} endIcon={<SkipNextIcon />}>
                                Next
                            </Button>
                            <Button type='submit' style={{ display: showFinish }} className="user-btn finish"  variant="contained" color="warning"  endIcon={<CollectionsBookmarkIcon />}>
                                Finish
                            </Button>
                            </Stack>
                        </FormControl>
                        </form>
                    </div>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </div>
    <div style={{display:showexp}}>
      <div className="slexpire">
        Survey has ended.
      </div>

    </div>
    </>
  )
}

export default SurveyHome



const Question=({id,formData, setFormData, qesData,datalength,optarray,setquesno,setquestype}) => {
        

  return (<><Singlequestions formData={formData} setFormData={setFormData} qesData={qesData} optarray={optarray} id={id} setquesno={setquesno} setquestype={setquestype}/></>);


}