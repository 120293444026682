import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./Sidemenu.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import TopicIcon from '@mui/icons-material/Topic';
import PollIcon from '@mui/icons-material/Poll';

import EmailIcon from '@mui/icons-material/Email';

import InsightsIcon from '@mui/icons-material/Insights';
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  
  return (
    <MenuItem
      active={selected === title}
      style={{
      }}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
      
    </MenuItem>

    
  );
};

const Sidemenu = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    let  str2= '';
    if(path===''){
        str2 ='Dashboard'
    }else if(path==='emails'){
      str2 ='Email Templates'
    }else if(path==='survey-questions'){
      str2 ='Surveys'
    }
    else if(path==='survey-guide'){
      str2 ='User Guide'
    }
    else if(path==='survey-results' || path==='survey-answer'){
      str2 ='Vendor Responses'
    }
    else if(path==='survey-reports' || path==='survey-summary'){
      str2 ='Survey Reports'
    }
    else{
      str2 = path.charAt(0).toUpperCase() + path.slice(1);
    }
    
  const [selected, setSelected] = useState(str2);

  return (
    <Box
      style={{backgroundColor:'#141b2d'}}
    >
      <Sidebar collapsed={isCollapsed}
      style={{}}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon style={{color:'#fff'}}/> : undefined}
            style={{
              margin: "10px 0 20px 0",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography variant="h3" >
                  PG COUNTY
                </Typography> */}
                <img src={`/cos.jpeg`} className="admin-logo" alt="logo"/>
                <IconButton style={{color:'#fff'}} onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          {/* <Link to={'/admin'}   ><img src={`/cos.jpeg`} className="admin-logo" alt="logo"/></Link> */}
            <Item
              title="Dashboard"
              to="/admin/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              sx={{ m: "15px 0 5px 20px" }}
            >
              Manage List
            </Typography>
            
            <Item
              title="Surveys"
              to="/admin/surveys"
              icon={<PollIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Vendors"
              to="/admin/vendors"
              icon={<SwitchAccountIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Projects"
              to="/admin/projects"
              icon={<AutoAwesomeMotionIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            
            <Item
              title="Email Templates"
              to="/admin/emails"
              icon={<EmailIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
            <Item
              title="Admin Users"
              to="/admin/users"
              icon={<PeopleOutlinedIcon  />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              sx={{ m: "15px 0 5px 20px" }}
            >
              Reports
            </Typography>

            <Item
              title="Vendor Responses"
              to="/admin/survey-results"
              icon={<InsightsIcon  />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Survey Reports"
              to="/admin/survey-reports"
              icon={<BarChartOutlinedIcon  />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="User Guide"
              to="/admin/survey-guide"
              icon={<TopicIcon  />}
              selected={selected}
              setSelected={setSelected}
            />
            
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default Sidemenu;
