import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import axios from "axios";
import swal from "sweetalert";
import dayjs from 'dayjs';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Button from '@mui/material/Button';

import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from 'react-router-dom';

const wider ={
  width : '99%',
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'white',
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb:4,
};
const api_uri = process.env.REACT_APP_API_URL

const SurveyDash = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const surveyid = queryParameters.get("survey")
  const type = queryParameters.get("ty")

  let title ;
  let linked ;
  if(type==='ts'){
    title ="Survey Sent"
    linked="resultts"
  }else if(type==='arc'){
    title ="Survey Responses Collected from Sent Emails"
    linked="resultarc"
  }else if(type==='apr'){
    title ="Awaiting Responses from Sent Emails"
    linked="resultapr"
  }else if(type==='ars'){
    title ="Responses Collected via Survey Link"
    linked="resultars"
  }else if(type==='trs'){
    title ="Survey Responses Collected"
    linked="resulttrs"
  }
  const [sel_temp, setsel_temp] = useState('')

  
  const [surveylist, setsurveylist ]=useState([])
    const [Resultlist, SetResultdata] = useState([])
    const navigate = useNavigate();
    const [opensend, setOpensend] = React.useState(false);
    const handleOpensend = () => setOpensend(true);
    const handleClosesend = () => setOpensend(false);

    

    const call_fetch = () =>{
                

                fetch(api_uri+"/"+linked+"/"+surveyid)
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    SetResultdata(res)           
                }).catch((error) => {
                    
                });


      
    }
    const call_fetch_survey= () =>{
      fetch(api_uri+"/emailtemplategroup/"+surveyid)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setsurveylist(res)           
            }).catch((error) => {
                
            });
    }
    
    const onClickQes = (e) => {
        let id = e.row.id
        
        navigate('/admin/survey-answer?survey='+id);
      }

    useLayoutEffect(() => {    
      
      
        call_fetch()
        call_fetch_survey()
        
    },[]);

    const handleSendmail = () => {
      const listid =selectedRows.join(',')
      if(listid){
        handleOpensend()
      }else{
        swal("Please select records", "",{ button: "OK",});
      }
      
    }
    let columns;

    if(type==="arc" || type==="ars" || type==="trs"){
    
      columns = [
        // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
        { field: "email", headerName: "Vendor Email", headerClassName: 'super-app-theme--header', width: 250}, 
        { field: "sent", headerName: "Status", headerClassName: 'super-app-theme--header', width: 180, align:'center',headerAlign:'center',
          renderCell:(params) => {
                if(params.value===0){
                return  <div> <span className='badge-blue'>Newly Added</span></div>
                }else{
                  return  <div><span className='badge-green'>From Survey Sent</span></div>
                }

            },
        },
        { field: "title", headerName: "Survey", headerClassName: 'super-app-theme--header',width: 300,headerAlign:'center', align:'center' },
        { field: "CreatedDt", headerName: "Date Completed" , headerClassName: 'super-app-theme--header', width: 200 ,valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY')  },
        {
          field: 'action',
          headerName: 'Action',
          headerAlign:'center',
          align:'center',
          
          width: 200,
          sortable: false,
          headerClassName: 'super-app-theme--header',
          disableClickEventBubbling: true,
          
          renderCell: (params) => {
              
              
              return (
                <Stack direction="row" spacing={2} justifyItems={'center'}>
                  <Button className='adm-btn btg' variant="contained" style={{background:'#4caf50'}} size="small" onClick={()=> onClickQes(params)}><ViewQuiltIcon/>View Responses</Button>
                  
                </Stack>
              );
          },
        }
      ];
    }
    else if(type==="ts" || type==="apr"){

      columns = [
        // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
        { field: "email", headerName: "Vendor Email", headerClassName: 'super-app-theme--header', width: 250}, 
        { field: "resu", headerName: "Survey Status", headerClassName: 'super-app-theme--header', width: 180, align:'center',headerAlign:'center',
          renderCell:(params) => {
                if(params.value===null){
                return  <div> <span className='badge-red'>Incomplete</span></div>
                }else{
                  return  <div><span className='badge-green'>Completed</span></div>
                }

            },
        }
      ];


    }
    
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const onChoose=(e)=>{
    const listid =selectedRows.join(',')
    setsel_temp(e.target.value)
    swal("Are you sure you want to send?","", {
      buttons: {
        cancel: "Cancel",
        confirm: true,
      },
    })
    .then((value) => {
      if(value===true){
   
      axios.post(api_uri+"/sendremainder", {            
        vendors:listid,
        id:e.target.value,
        count:selectedRows.length,        

    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          toast.success("Invites send to vendors!!", {position: "top-right",theme: "colored"});
          


        })
        .catch((error) => {
            swal("", "Failed to send invites!", "error",{ button: "Try Again!",});
        });
      }
      setsel_temp("")
      handleClosesend()
    });

  }

  const options = surveylist.map((obj)=>
    <MenuItem key={obj.id}  value={obj.id}>{obj.template_name}</MenuItem>
  );
  return (
    <Box p="20px" >
    <Header
      title={title}
      subtitle="Dashboard "
    />
    {
      type==='apr'?
      <Stack direction="row" spacing={2} justifyContent={'center'}  marginTop={2}>
          
            <Button onClick={handleSendmail} variant="contained" color="success" startIcon={<ForwardToInboxIcon />}>
              Send Invite
            </Button>
          
      </Stack> :''
    }
    
    
    <Box
      m="20px 0 0 0"
      className="bg-white"
      sx={wider}
      
    >
      <DataGrid checkboxSelection disableRowSelectionOnClick
        rows={Resultlist}
        columns={columns}
        pageSizeOptions={[10, 25,50,100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        components={{ Toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        onRowSelectionModelChange={(ids) => {
            
          setSelectedRows(ids);
        }}
      />
    </Box>
    <Modal
        open={opensend}
        onClose={handleClosesend}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        
        <Box sx={style}>
          <div  style={{display:'flex',justifyContent:'space-between'}}>
          <h3>Select Email Template</h3>
          <Tooltip title="Close">
            <IconButton color="#000" className='modal-close'  onClick={handleClosesend}>
                <CloseIcon/>
                </IconButton>
          </Tooltip>
          </div>
          <FormControl sx={{ m: 1, width: '100%' }}>
            <Select              
              onChange={onChoose}
              value={sel_temp}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              
              {options}
            </Select>
          </FormControl>
          
          <div>

          </div>
          
        </Box>
        
      </Modal>
    
    <ToastContainer autoClose={1000}/>
  </Box>
  )
}

export default SurveyDash
