import React from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";


function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + (20*60*1000),
  );
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

const api_uri = process.env.REACT_APP_API_URL
const Register = () => {
  const navigate = useNavigate();




  const handleRegister = (event) => {
    event.preventDefault();
    const name=event.target.name.value
    const email=event.target.email.value
    const password=event.target.password.value
    const token = true;

    if(email.toLowerCase().includes("@zovaya.com")!== true && email.toLowerCase().includes("co.pg.md.us")!== true)    {
    
      swal("Invalid email domain","",  "error",{ button: "Try Again!",});
    }else{
      axios.post(api_uri+"/signup", {            
            
        name:name,
        email:email,
        password:password,
        token: token
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            navigate('/admin');
        })
        .catch((error) => {
            swal("Oops", "Registration failed", "error",{ button: "Try Again!",});
        });
    }
    
    
    

  };


  return (
    <div>
      
    <div className='backimage' style={{backgroundImage:`url(./lobg.png)`}}>

    <div className='map-bdy'>
      <div className='inner-map-bdy' style={{width:700}}>
        
      <div id="sign-in" className="auth-form   form-validation ">
                <h3 className='map-head'>Register</h3>
                <form onSubmit={handleRegister}  className="form-validate">
                  <div className="form-group mb-5">
                    <label className="mb-2 auth-labels"  htmlFor="val-email">Username</label>
                    <div>
                      <input type="text" className="form-control" name="name" id="name" placeholder="Type Your Username" required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <label className="mb-2 auth-labels"  htmlFor="val-email">Email</label>
                    <div>
                      <input type="email" className="form-control" autoComplete='off' name="email" id="email" required placeholder="Type Your Email Address"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2 auth-labels">Password</label>
                    <input
                      type="password" autoComplete='off'
                      name="password" id="password"
                      className="form-control"
                      placeholder="Type Your Password"
                      required
                    />
                    
                    
                  </div>
                  <div className="text-center form-group mb-3">
                    
                    <button type="submit" className=" btn-sign  ">
                      Sign In
                    </button>
                    
                  </div>
                </form>
                <div className="sign-account mt-3">
                  <p>Already have an account? <Link className="text-sign" to="/login">Log in</Link></p>
                </div>
              </div>
      </div>
    </div>


    </div>
    
  </div>
  );
};

export default Register;
