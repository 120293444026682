import React, { useLayoutEffect, useState } from 'react'
import { Box  , Grid, Card} from "@mui/material";
import Header from "./Header";

import AnswerQes from './AnswerQes';

const api_uri = process.env.REACT_APP_API_URL



const SurveyAnswer = () => {

  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("survey")

  const bank = {
    surveyid:id, 
    elements:[],

  };

  const [singleData, setsingleData] = useState([])
  const [ansData, setansData] = useState([])
  const [formData, setFormData]= useState(bank);
  const [optarray, setoptarray]= useState([])
  const call_fetch = () =>{
    fetch(api_uri+"/singleresult/"+id)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
            setsingleData(res[0])   
            if(res[0].questions){
              setFormData(JSON.parse(res[0].questions))
              setansData(JSON.parse(res[0].answer.replace(/\\'/g, "\'")))
              setoptarray(Object.keys(JSON.parse(res[0].answer.replace(/\\'/g, "\'"))))
            }        
          }).catch((error) => {
              
          });
  }

  useLayoutEffect(() => {    
      
      
    call_fetch()
    
  },[]);

  const Question = formData.elements.map((qes,index)=> optarray.includes(qes.qesid)||optarray.includes(qes.qesid+'_1')?
      
  <AnswerQes key={index} id={index+1} props={qes} formData={formData} setFormData={setFormData} ansData={ansData} optarray={optarray}/>:''
    

  )

  return (
    <Box p="20px" >
        <Header
          title={"Vendor : "+singleData.email}
          subtitle={"Dashboard / Vendor Responses / Survey : "+singleData.title}
        />

        
        <Box
          m="20px"
          className="bg-white" 
        >
          <Grid container spacing={0} display="flex" justifyContent={'center'}>
            <Grid item xs={12} md={12}   >
              <Card className="sur-card">
                   
                  
                {Question}
                

                </Card>
            </Grid>
          </Grid>

        </Box>

         
        
        
      </Box>
  )
}

export default SurveyAnswer
