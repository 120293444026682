import React, { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';

import { Box, Card} from "@mui/material";


import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import BarChart from './charts/BarChart';
import Pie from './charts/pie';
import MultiBarChart from './charts/MultiBarChart';

const genhsl = () => {
  const ehsl =["#dae162","#dae162", "#fb7470","#e0c39d","#26d9b5","#d562d5","#59c5e1","#8d6e63","#ba68c8","#91cc75","#ee6666","#fac858","#5470c6","#73c0de","#cddc39","#ffc107","#405180","#F7C4AF","#D098AF","#B184B1","#8469A9","#D5EE06","#F83699","#F68E00","#672BAF","#FFEA0E","#0087F9","#647F57","#E88269","#4C85BD","#EAE0D0","#D7B27B"] 
  
  let pick = Math.floor(Math.random() * 31 + 0);
  return ehsl[pick];
  // let randomColorString = "#";
  // const arrayOfColorFunctions = "0123456789abcdef";
  // for (let x = 0; x < 6; x++) {
  //   let index = Math.floor(Math.random() * 16);
  //   let value = arrayOfColorFunctions[index];

  //   randomColorString += value;
  // }
  // return randomColorString;

}

function getOccurrence(array, value) {
  return array.filter((v) => (v === value)).length;
}

const pieData =[]
function AnswerSum({id,props,formData,answers,optarray}) {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    

  
        
    if(props.type==='radio'){
      let ans_choice = []
      answers.map((ans,index)=>{       
        
        if(ans[props.qesid]){
          ans_choice.push(ans[props.qesid])
        }        

      })
      // console.log(props.qesid)
      // console.log(ans_choice)
      let total =ans_choice.length
      let pieData = []
      props.choices.map((cho,index)=>  {
        var id ="Choice "+(index+1)
        var label ="Choice "+(index+1)
        if(cho.text.length <=50){
          id =cho.text
        }
        if(cho.text.length <=30){
          label =cho.text
        }

        const data={
          id: id,
          label: label,
          value: getOccurrence(ans_choice,(cho.value).toString()),
          color:  genhsl()
        } 
        pieData.push(data)   

      })
      let legen = true

      if(pieData.length >5){
        legen = false
      }

  return (
    <Card className='questions-card'  ref={componentRef} >
     
        <h3 className='qes-title'>
            Q{id}. {props.title}
            <div className='expo-pr'><Tooltip title="Export"><IconButton variant="outlined"  size="small" onClick={handlePrint}><FileDownloadIcon/></IconButton></Tooltip></div>
        </h3>
         
        <div >
        <div className='answered-count'>Answered : {total}</div>

            <Box height="50vh"  className='charts-pos'>
                    <Pie data={pieData} legen={legen} />
            </Box>


                <div className='table-responsive '>
                        <table className='table verticle-middle text-nowrap table-responsive-md sum-table'>
                        <thead>
                            <tr>
                            <th scope='col' className='text-center'>CHOICE</th>
                            <th scope='col' className='text-center'>ANSWER CHOICES</th>
                            <th scope='col'className='text-center'>RESPONSES %</th>
                            <th scope='col'className='text-center' >RESPONSE COUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                        {props.choices.map((cho,index)=>  

                                <tr key={index}>
                                <td className='text-center'>{index+1}</td>
                                <td>{cho.text}</td>
                                <td className='text-center'>{total===0?0:((getOccurrence(ans_choice,(cho.value).toString()))*100/total).toFixed(2)}%</td>
                                <td className='text-center'>{getOccurrence(ans_choice,(cho.value).toString())}</td>
                                </tr>
             
                            )
                        }
                            
                        </tbody>
                        <tfoot>
                            <tr>
                            <th scope='col' colSpan="3">TOTAL</th>
                            <th scope='col'  className='text-center'>{total}</th>
                            </tr>
                        </tfoot>

                        </table>
                    </div>


        </div>
    </Card>
  )
}
else if(props.type==='checkbox'){
      let ans_choice = []
      answers.map((ans,index)=>{       
        
        if(ans[props.qesid]){
          ans_choice=ans_choice.concat(ans[props.qesid])
        }        

      })
      let total =ans_choice.length
      let barData = []
      var layout ="vertical"
      var height ="50vh"
      if(props.choices.length >6){
        layout="horizontal"
        height ="80vh"
      }
      props.choices.map((cho,index)=>  {
        var label ="Choice "+(index+1)
        if(cho.text.length <=10){
          label =cho.text
        }

        const data={
          labels: label,
          Responses:getOccurrence(ans_choice,(cho.value).toString()),
          color:  genhsl()
        } 
        barData.push(data)   

      })
  
  


return (
<Card className='questions-card'  ref={componentRef} >
 
    <h3 className='qes-title'>
        Q{id}. {props.title}
        <div className='expo-pr'><Tooltip title="Export"><IconButton variant="outlined"  size="small" onClick={handlePrint}><FileDownloadIcon/></IconButton></Tooltip></div>
    </h3 >
     <div>
    <div className='answered-count'>Choosed Answered : {total}</div>

        <Box height={height}  className='charts-pos1'>
                <BarChart  data={barData} layout={layout}/>
                </Box> 
                <div className='table-responsive '>
                        <table className='table verticle-middle text-nowrap table-responsive-md sum-table'>
                        <thead>
                            <tr>
                            <th scope='col' className='text-center'>CHOICE</th>
                            <th scope='col' className='text-center'>ANSWER CHOICES</th>
                            <th scope='col'className='text-center'>RESPONSES %</th>
                            <th scope='col'className='text-center' >RESPONSE COUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                        {props.choices.map((cho,index)=>  

                                <tr key={index}>
                                <td className='text-center'>{index+1}</td>
                                <td>{cho.text}</td>
                                <td className='text-center'>{total===0?0:((getOccurrence(ans_choice,(cho.value).toString()))*100/total).toFixed(2)}%</td>
                                <td className='text-center'>{getOccurrence(ans_choice,(cho.value).toString())}</td>
                                </tr>
             
                            )
                        }
                            
                        </tbody>
                        <tfoot>
                            <tr>
                            <th scope='col' colSpan="3">TOTAL</th>
                            <th scope='col'  className='text-center'>{total}</th>
                            </tr>
                        </tfoot>

                        </table>
                    </div> 
    </div>


</Card>
)
}
else if(props.type==='multiboolean'){
  let ans_choice = []
  let ans_choice_bool = []
  props.choices.map((cho,index)=>{
    
    answers.map((ans,index)=>{       
    
      if(ans[props.qesid+'_'+cho.value] ){ 
        

        ans_choice_bool.push(ans[props.qesid+'_'+cho.value])
      } 
    }) 
    ans_choice [props.qesid+'_'+cho.value] = ans_choice_bool

    // ans_choice.push(arr)
    ans_choice_bool = []
    // ans_choice=ans_choice.concat(ans[props.qesid]) 

  })
  // console.log(props.qesid)
  // console.log(ans_choice)
  let total =ans_choice[props.qesid+'_1'].length
  let barData = []
  var layout ="vertical"
  var height ="50vh"
  if(props.choices.length >6){
    layout="horizontal"
    height ="80vh"
  }
  props.choices.map((cho,index)=>  {
    var label ="SUB QUEST "+(index+1)
    if(cho.text.length <=10){
      label =cho.text
    }

    const data={
      labels: label,
      Yes:getOccurrence(ans_choice[props.qesid+'_'+cho.value],"1"),
      No:getOccurrence(ans_choice[props.qesid+'_'+cho.value],"2"),
      Neutral:getOccurrence(ans_choice[props.qesid+'_'+cho.value],"3"),
    } 
    barData.push(data)   

  })




return (
<Card className='questions-card'  ref={componentRef} >

<h3 className='qes-title'>
    Q{id}. {props.title}
    <div className='expo-pr'><Tooltip title="Export"><IconButton variant="outlined"  size="small" onClick={handlePrint}><FileDownloadIcon/></IconButton></Tooltip></div>
</h3>
 <div >
  <div className='answered-count'>Answered : {total}</div>

    <Box height={height}  className='charts-pos1'>
            <MultiBarChart  data={barData} layout={layout}/>
            </Box> 
            <div className='table-responsive '>
                    <table className='table verticle-middle text-nowrap table-responsive-md sum-table'>
                    <thead>
                        <tr>
                        <th scope='col' className='text-center' colSpan={2}></th>
                        <th scope='col'className='text-center' colSpan={3} >RESPONSES %</th>
                        <th scope='col'className='text-center' colSpan={4} >RESPONSE COUNT</th>
                        </tr>
                        <tr>
                        <th scope='col' className='text-center'>CHOICE</th>
                        <th scope='col' className='text-center'>SUB QUESTIONS</th>
                        <th scope='col' className='text-center'>YES</th>
                        <th scope='col' className='text-center'>NO</th>
                        <th scope='col' className='text-center'>Neutral</th>
                        <th scope='col' className='text-center'>YES</th>
                        <th scope='col' className='text-center'>NO</th>
                        <th scope='col' className='text-center'>Neutral</th>
                        <th scope='col'className='text-center' >Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.choices.map((cho,index)=>  
                      // <></>
                            <tr key={index}>
                            <td className='text-center'>{index+1}</td>
                            <td>{cho.text}</td>
                            <td className='text-center'>{total===0?0:((getOccurrence(ans_choice[props.qesid+'_'+cho.value],"1"))*100/total).toFixed(2)}%</td>
                            <td className='text-center'>{total===0?0:((getOccurrence(ans_choice[props.qesid+'_'+cho.value],"2"))*100/total).toFixed(2)}%</td>
                            <td className='text-center'>{total===0?0:((getOccurrence(ans_choice[props.qesid+'_'+cho.value],"3"))*100/total).toFixed(2)}%</td>
                            <td className='text-center'>{getOccurrence(ans_choice[props.qesid+'_'+cho.value],"1")}</td>
                            <td className='text-center'>{getOccurrence(ans_choice[props.qesid+'_'+cho.value],"2")}</td>
                            <td className='text-center'>{getOccurrence(ans_choice[props.qesid+'_'+cho.value],"3")}</td>
                            <td className='text-center'>{(ans_choice[props.qesid+'_'+cho.value].length)}</td>
                            </tr>
         
                        )
                    }
                        
                    </tbody>

                    </table>
                </div> 
</div>


</Card>
)
}
else{
 


return (
<></>
)
}
}

export default AnswerSum



  