import React,{useState} from 'react'

import { Box , Stack , Grid, Card} from "@mui/material";

import Button from '@mui/material/Button';

import PinIcon from '@mui/icons-material/Pin';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListIcon from '@mui/icons-material/List';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
const styleadd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'white',
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb:4,
  pt:1,
};


function Qespage({id,props,formData, setFormData}) {


  const [optionchoice, setoptionchoice] = useState('');
  const [inputList, setInputList] = useState([]);
  const [choice,setchoice]=useState(false)
  
  const [choicelength, setchoicelength] = useState(2);
  const [openadd, setOpenadd] = React.useState(false);
  const handleOpenadd = () => setOpenadd(true);
  const handleCloseadd = () => {
    setOpenadd(false)
    setInputList([])
  };
  const addchoice = () =>{
    setchoicelength(inputList.length+props.choices.length+1)
    setInputList(inputList.concat(<Child id={inputList.length+props.choices.length+1} key={inputList.length} />));

  }
  const editqes = (e) => {
    var type= e.type
    if(type==='radio' || type==='checkbox' || type==='multiboolean'){
      
     setchoice(true)
     const arch= e.choices.map((cho,index)=> 
                  <div className='choice' key={index}><label>Choices {index+1}:</label><textarea className="form-control" id={"cho"+(index+1)} name={"cho"+(index+1)} placeholder='option' defaultValue={cho.text}></textarea></div>
                )
     setoptionchoice(arch)
     setchoicelength(e.choices.length)
    }else{
     setchoice(false)
    }
     handleOpenadd()
 };
    

    const delqes =(e) => {
        const array = formData['elements']
        const removearr =[e]
        const toRemove = new Set(removearr);
        
    
        const difference = array.filter( x => !toRemove.has(x) );
        setFormData({...formData, elements: difference})
    }
        
    
    const UpdateQes =(event)=> {
      event.preventDefault();
      const array = formData['elements']

    
    if(event.target.qtype.value==='radio' || event.target.qtype.value==='checkbox' || event.target.qtype.value==='multiboolean'){
      const choices = []
     let j=1;
      for(let k=1;k<=choicelength;k++){
        let optio ='cho'+k

        if(event.target.elements[optio].value){
          const opt = {
            value:j,
            text:event.target.elements[optio].value
          }
          choices.push(opt)
          j++;

        }
      }
      const qesarr = {
        type:event.target.qtype.value,
        // name:event.target.name.value,
        title:event.target.qestitle.value,
        description:event.target.description.value,
        note:event.target.note.value,
        choices:choices,
        qesid: event.target.qesid.value
      }
      array[(id-1)] = qesarr;

    }else{
      const qesarr = {
        type:event.target.qtype.value,
        // name:event.target.name.value,
        title:event.target.qestitle.value,
        description:event.target.description.value,
        note:event.target.note.value,
        qesid: event.target.qesid.value
      }
      array[(id-1)] = qesarr;
    } 
    setFormData({...formData, elements: array})
      handleCloseadd()
    }

  return (
    <Card className='questions-card'  >
      
      <div className='qes-type' >
        <div>Question.{id}</div>
        
        <div>
         {props.type==='radio'? (<div className='icon-list'><ListIcon style={{marginRight:10}}/> Radio Buttons</div>) :''}
         {props.type==='text'? (<div className='icon-list'><BorderColorIcon style={{marginRight:10}}/>  Single Line Text</div>) :''}
         {props.type==='checkbox'? (<div className='icon-list'><CheckBoxIcon style={{marginRight:10}}/> Checkboxes</div>) :''}
         {props.type==='multiboolean'? (<div className='icon-list'><ListIcon style={{marginRight:10}}/> Multiple SubChoices</div>) :''}
         {props.type==='textarea'? (<div className='icon-list'><TextSnippetIcon style={{marginRight:10}}/> Multi Line Text</div>) :''}
         {props.type==='email'? (<div className='icon-list'><AlternateEmailIcon style={{marginRight:10}}/> Input Email</div>) :''}
         {props.type==='number'? (<div className='icon-list'><PinIcon style={{marginRight:10}}/> Input Numbers</div>) :''}
         </div>
      </div>
      <div className='qes-title'>
         {props.title}
      </div>
      <div className='qes-short'>
         {props.description}
      </div>
      
      <div className='qes-feild'>
         {props.type==='text' || props.type==='textarea' || props.type==='number'? (<input type="text" readOnly className='input-feild' />):''}
         {props.type==='email'? (
          <div className='ct-frm'>
            <Grid container spacing={0} display="flex" justifyContent={'center'}>
              <Grid item xs={12} md={12}   >
                <div className='grp-ct'>
                  <label>Business Name</label>
                  <input type="text" readOnly className='' />
                </div>
              </Grid>
              <Grid item xs={12} md={12}   ><div className='grp-ct'><label>Business Contact :</label></div></Grid>
              <Grid item xs={12} md={6}   >
                <div className='grp-ct'>
                  <label>First Name</label>
                  <input type="text" readOnly className='' />
                </div>
              </Grid>
              <Grid item xs={12} md={6}   >
                <div className='grp-ct'>
                  <label>Last Name</label>
                  <input type="text" readOnly className='' />
                </div>
              </Grid>
              <Grid item xs={12} md={6}   >
                <div className='grp-ct'>
                  <label>Business Email</label>
                  <input type="text" readOnly className='' />
                </div>
              </Grid>
              <Grid item xs={12} md={6}   >
                <div className='grp-ct'>
                  <label>Business Phone</label>
                  <input type="text" readOnly className='' />
                </div>
              </Grid>
            </Grid>            
          </div>        
          
          ):''}
         {props.type==='radio'? (<div>
          {props.choices.map((cho,index)=> 
            <div className='radio-inputs' key={index}>              
              <input className='radio-btn' type='radio' name={props.qesid} />
              <label className='radio-label'>{cho.text}</label>
            </div>            
          
            )
          }
          
          </div>):''}
         {props.type==='checkbox'? (<div>
          {props.choices.map((cho,index)=> 
            <div className='radio-inputs' key={index}>              
              <input className='radio-btn' type='checkbox' name={props.qesid} />
              <label className='radio-label'>{cho.text}</label>
            </div>            
          
            )
          }
          
          </div>):''}
          {props.type==='multiboolean'? (<div>
          {props.choices.map((cho,index)=> 
            <div className='radio-inputs' key={index}>  
              <div className='multiboo'>{cho.text}</div>
              <div>
              <input className='radio-btn' type='radio' name={props.qesid+"_"+cho.value}/>
              <label className='radio-label'>Yes</label>
              <input className='radio-btn' type='radio' name={props.qesid+"_"+cho.value}/>
              <label className='radio-label'>No</label>
              <input className='radio-btn' type='radio' name={props.qesid+"_"+cho.value}/>
              <label className='radio-label'>Neutral</label>
              </div>            
              
            </div>            
          
            )
          }
          
          </div>):''}
      </div>
      
      <div className='qes-note'>
         {props.note}
      </div>

      <div className='qes-delete'>
      <Button className='' onClick={()=> editqes(props)} title="Edit Question"  color='secondary'  startIcon={<EditNoteIcon/>} >Edit Question {id}</Button>
      <Button className='' onClick={()=> delqes(props)} title="Delete Question"  color='error'  startIcon={<DeleteIcon/>} >Delete Question {id}</Button>
      </div>
      
      <Modal
            open={openadd}
            onClose={handleCloseadd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Edit Question {id}</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseadd}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={UpdateQes}>
                <div>
                  <div className='scroll-div'>
                    <div className='group-control'>
                      <label className="clabel">Question <span className='req-label'>*</span>:</label>
                      <textarea className="form-control" name="qestitle" id="qestitle" placeholder='Enter Full Question' required defaultValue={props.title} ></textarea>
                      
                    </div>

                    <div className={'group-control '+(choice? '':'d-none')}>
                      <label className="clabel"> Options </label>
                      {optionchoice}
                      
                      {inputList}
                      <div className="addchoice" onClick={addchoice}> <AddCircleOutlineIcon/> Add new option</div>
                    </div> 
                    <div className='group-control'>
                      <label className="clabel">Short Description/Instruction :</label>
                      <textarea className="form-control" name="description" id="description"placeholder='Short Description/Instruction' defaultValue={props.description}  ></textarea>
                    </div>
                    
                    <div className='group-control'>
                      <label className="clabel">Note :</label>
                      <textarea className="form-control" name="note" id="note"placeholder='Notes' defaultValue={props.note} ></textarea>
                    </div> 
                  </div>
                  
                  <div className='bottom-control'>
                    <input type='hidden' value={props.type} name="qtype"/>
                    <input type='hidden' value={props.qesid} name="qesid"/>
                    <Button type="submit" variant='contained' color='info'>Update</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
        
        {/* <ToastContainer autoClose={1000}/>         */}
    </Card>
  )
}

export default Qespage


const Child = ({id}) =>{
  return  <div className='choice'><label>Choices {id}:</label><textarea className="form-control" id={'cho'+id} name={'cho'+id} placeholder='option' ></textarea></div>
}