import React from 'react'

import {  Card} from "@mui/material";

import PinIcon from '@mui/icons-material/Pin';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListIcon from '@mui/icons-material/List';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';


function AnswerQes({id,props,formData, setFormData,ansData,optarray}) {
    

//   console.log(ansData[props.qesid])
//   console.log(props.qesid)
  
        
    

  return (
    <Card className='questions-card'  >
      
      <div className='qes-type1' >
        <div>Question.{id}</div>
        
        <div>
         
         </div>
      </div>
      <div className='qes-title'>
         {props.title}
      </div>
      
      <div className='qes-feild'>
         <div className='answer-field'>Answer:</div>
         {props.type==='email' || props.type==='text' || props.type==='textarea' || props.type==='number'? (<div className='text-answers'>{ansData[props.qesid]} </div>):''}
         {props.type==='radio'? (<div>
          {props.choices.map((cho,index)=>  ansData[props.qesid]===(cho.value.toString())?
             
            <div className='radio-inputs' key={index}>   
              <input className='radio-btn' type='radio' value={cho.value} checked="true" readOnly />
              <label className='radio-label'>{cho.text}</label>
            </div>
            :''            
          
            )
          }
          {(props.qesid==='qes_17' && ansData['qes_17']==='14'?<div className='sub-text-answer'> {ansData[props.qesid+'_1']}</div>:"")}
          {(props.qesid==='qes_18' && ansData['qes_18']==='1'?<div className='sub-text-answer'>Zipcode : {ansData[props.qesid+'_1']}</div>:"")}
          {(props.qesid==='qes_21' && ansData['qes_21']==='1'?<div className='sub-text-answer'>Agency : {ansData[props.qesid+'_1']==="Others"?ansData[props.qesid+'_2']:ansData[props.qesid+'_1']}</div>:"")}
          
          </div>):''}


         {props.type==='checkbox'? (<div>
          {props.choices.map((cho,index)=> ansData[props.qesid].includes((cho.value.toString()))?
            <div className='radio-inputs' key={index}>              
              <input className='radio-btn' type='checkbox' value={cho.value}  readOnly checked="true" />
              <label className='radio-label'>{cho.text}</label>
            </div>            
             :''
            )
          }
          
          </div>):''}
          {props.type==='multiboolean'? (<div>
          {props.choices.map((cho,index)=> 
            <div className='radio-inputs' key={index}>  
              <div className='multiboo'>{cho.text}</div>
              <div>
              <input className='radio-btn' type='radio' value={ansData[props.qesid+'_'+cho.value]} checked readOnly />
              <label className='radio-label'>{ansData[props.qesid+'_'+cho.value]==="1"?'Yes':ansData[props.qesid+'_'+cho.value]==="2"?'No':'Neutral'}</label>
              </div>            
              
            </div>            
          
            )
          }
          
          </div>):''}
      </div>
      
     

      
                  
    </Card>
  )
}

export default AnswerQes
