import React, { useLayoutEffect, useState } from 'react'
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import { useTheme } from "@mui/material";


const api_uri = process.env.REACT_APP_API_URL

function Contractors() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [Vendorlist, SetVendordata] = useState([])

    
    
    
    const columns = [
      { field: "id", headerName: "ID", flex: 0.5 },
      { field: "business_name", headerName: "Business Name", flex: 1 },
      { field: "business_email", headerName: "Business Email", flex: 1  },
      { field: "business_address", headerName: "Business Address" , flex: 1 },
      { field: "contact_person", headerName: "Contact Person", flex: 1  },
      { field: "speciality_area", headerName: "Speciality Area", flex: 1  },
      { field: "CCS", headerName: "CCS" , flex: 1 },
      
    ];
    
    useLayoutEffect(() => {

      fetch(api_uri+"/vendorlist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                SetVendordata(res)           
            }).catch((error) => {
                
            });
            console.log(Vendorlist);
      
  });
    return (
      <Box m="20px">
        <Header
          title="Contractors"
          subtitle="List of Contractors for Future Reference"
        />
        <Box
          m="40px 0 0 0"
          height="100vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={Vendorlist}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
    );
  };

export default Contractors


