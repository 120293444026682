
import { ResponsiveBar } from "@nivo/bar";

const generateColor = () => {
  const ehsl =["#dae162","#dae162", "#fb7470","#e0c39d","#26d9b5","#d562d5","#59c5e1","#8d6e63","#ba68c8","#91cc75","#ee6666","#fac858","#5470c6","#73c0de","#cddc39","#ffc107","#405180","#F7C4AF","#D098AF","#B184B1","#8469A9","#D5EE06","#F83699","#F68E00","#672BAF","#FFEA0E","#0087F9","#647F57","#E88269","#4C85BD","#EAE0D0","#D7B27B"] 
  
  let pick = Math.floor(Math.random() * 31 + 0);
  return ehsl[pick];
};


const BarChart = ({data,layout}) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["Responses"]}
      indexBy="labels"
      margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
      padding={0.4}
      layout={layout}
      valueScale={{ type: "linear" }}
      colors={{ datum: 'data.color' }}
      animate={true}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
    />
  );
};

export default BarChart;
