import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import axios from "axios";
import swal from "sweetalert";
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';

const api_uri = process.env.REACT_APP_API_URL
const wider ={
  width : 'auto',
  minWidth :'90%'
  
}
function Reports() {

  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("survey")

  const bank = {
    surveyid:id,
    elements:[],

  };

  const [singleData, setsingleData] = useState([])

  const [datalist, setdatalist] = useState([])
  const [ansData, setansData] = useState([])
  const [formData, setFormData]= useState(bank);

  const call_fetch = () =>{
    fetch(api_uri+"/singlereport/"+id)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
            setdatalist(res)           
          }).catch((error) => {
              
          });
          fetch(api_uri+"/survey/"+id)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
            setsingleData(res[0])   
            if(res[0].questions){
              setFormData(JSON.parse(res[0].questions))
            }        
          }).catch((error) => {
              
          });
  }



  


const columns = [
  // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
  { field: "email", headerName: "Vendor Email", headerClassName: 'super-app-theme--header', width:200  },
  { field: "sdate", headerName: "Date Completed" , headerClassName: 'super-app-theme--header',headerAlign:'center',align:'center', width:120 ,valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY')  },
  
];
if(formData['elements']){


  formData.elements.map((onedata,index)=>{
    
    const coladd ={ field:onedata.qesid,headerName:onedata.title, width: 750, headerClassName: 'super-app-theme--header',renderCell: (params) => {
      var result ='';
      let single_ans =JSON.parse(params.row.answer.replace(/\\'/g, "\'"))  
      if(onedata.type==="radio"){
        onedata.choices.map((cho,index)=> {
          if(single_ans[onedata.qesid]===(cho.value.toString())){
            result=cho.text
          }
        })
        
        if(onedata.qesid==='qes_17' && single_ans['qes_17']==='14'){
          result=single_ans[onedata.qesid+'_1']
       }
       if(onedata.qesid==='qes_18' && single_ans['qes_18']==='1'){
        result=single_ans[onedata.qesid+'_1']
       }
       if(onedata.qesid==='qes_21' && single_ans['qes_21']==='1'){
         if(single_ans[onedata.qesid+'_1']==='Others'){
          result=single_ans[onedata.qesid+'_2']
         }else{
          result=single_ans[onedata.qesid+'_1']
         }
       }

      }else if(onedata.type==="checkbox"){
        onedata.choices.map((cho,index)=> {
          if(single_ans[onedata.qesid]){
            if(single_ans[onedata.qesid].includes((cho.value.toString()))){
              result+=cho.text+" , "
            }
          }
        })
        
      }else if(onedata.type==="multiboolean"){

        onedata.choices.map((cho,index)=> {
          if(single_ans[onedata.qesid+'_'+cho.value]){
            const mb=single_ans[onedata.qesid+'_'+cho.value];
            if(mb==='1'){
              result+="Yes ,"
            }else if(mb==='2'){
              result+="No ,"
            }else if(mb==='3'){
              result+="Netural ,"
            }
          }
        })

        
      }else{
        result=single_ans[onedata.qesid]
      }
      
      return (<>{result}</>);},
      valueGetter:(params) => {
        var value =''
        let getter =JSON.parse(params.row.answer.replace(/\\'/g, "\'"))  
        if(onedata.type==="radio"){
          onedata.choices.map((cho,index)=> {
            if(getter[onedata.qesid]===(cho.value.toString())){
              value=cho.text
            }
          })
          if(onedata.qesid==='qes_17' && getter['qes_17']==='14'){
             value=getter[onedata.qesid+'_1']
          }
          if(onedata.qesid==='qes_18' && getter['qes_18']==='1'){
            value=getter[onedata.qesid+'_1']
          }
          if(onedata.qesid==='qes_21' && getter['qes_21']==='1'){
            if(getter[onedata.qesid+'_1']==='Others'){
              value=getter[onedata.qesid+'_2']
            }else{
              value=getter[onedata.qesid+'_1']
            }
          }
  
        }else if(onedata.type==="checkbox"){
          onedata.choices.map((cho,index)=> {
            if(getter[onedata.qesid]){
              if(getter[onedata.qesid].includes((cho.value.toString()))){
                value+=cho.text+" , "
              }
            }
          })
          
        }else if(onedata.type==="multiboolean"){

          onedata.choices.map((cho,index)=> {
            if(getter[onedata.qesid+'_'+cho.value]){
              const mb=getter[onedata.qesid+'_'+cho.value];
              if(mb==='1'){
                value+="Yes ,"
              }else if(mb==='2'){
                value+="No ,"
              }else if(mb==='3'){
                value+="Netural ,"
              }
            }
          })
  
          
        }else{
          value=getter[onedata.qesid]
        }
        return value

      }  
      
        
      }
    columns.push(coladd)
  })

  
  
}

useLayoutEffect(() => {    
      
      
  call_fetch()


  
},[]);





  
const [selectedRows, setSelectedRows] = React.useState([]);
const [paginationModel, setPaginationModel] = React.useState({
  pageSize: 25,
  page: 0,
});
  return (
    <Box p="20px" >
      <Header
        title={singleData.title}
        subtitle="Dahboard / Survey Reports / Cumulative Reports"
      />
      <Box
        m="20px 0 0 0"
        className="bg-white"
        sx={wider}
        
      >
        <DataGrid checkboxSelection disableRowSelectionOnClick
          rows={datalist}
          columns={columns}
          pageSizeOptions={[10, 25,50,100]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          components={{ Toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onRowSelectionModelChange={(ids) => {
              
            setSelectedRows(ids);
          }}
        />
      </Box>
      
    </Box>
  );
}

export default Reports
