import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import axios from "axios";
import swal from "sweetalert";
// import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import Tooltip from '@mui/material/Tooltip';


import Modal from '@mui/material/Modal';

const wider ={
  width : '100%',
}
const api_uri = process.env.REACT_APP_API_URL
const styleadd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'white',
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb:4,
};

function Emails() {
  
    const [datalist, setdatalist] = useState([])
    const [surveylist, setsurveylist ]=useState([])
    
    const [openadd, setOpenadd] = React.useState(false);
    const handleOpenadd = () => setOpenadd(true);
    const handleCloseadd = () => setOpenadd(false);
    
    const [openedit, setOpenedit] = React.useState(false);
    const handleOpenedit = () => setOpenedit(true);
    const handleCloseedit = () => setOpenedit(false);
    const [editdata, seteditdata] = useState([])

    const call_fetch = () =>{
      fetch(api_uri+"/emailtemplatelist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setdatalist(res)           
            }).catch((error) => {
                
            });
    }
    const call_fetch_survey= () =>{
      fetch(api_uri+"/surveylist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setsurveylist(res)           
            }).catch((error) => {
                
            });
    }
    const onDelete = (e) => {
      const id = e.row.id;
      swal({
        title: "Are you sure you want to delete this template?",
        text: "",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) { 
              axios.get(api_uri+"/emailtemplatedelete/"+id, {
                  headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
                })
                .then((response) => {
                  call_fetch()
                  toast.success("Record has been deleted !", {position: "top-right",theme: "colored"});
                })
                .catch((error) => {
                    swal("", "Fail to delete the record", "error",{ button: "Try Again!",});
                });
          }
        })
    };
    const onClickEdit = (e) => {
      seteditdata(e.row)
      handleOpenedit()
    };

    useLayoutEffect(() => {    
      
      
        call_fetch()
        call_fetch_survey()
        
    },[]);
    
    
    const columns = [
      // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
      { field: "template_name", headerName: "Template Name", headerClassName: 'super-app-theme--header', width: 250 },
      { field: "subject", headerName: "Subject", headerClassName: 'super-app-theme--header', width: 250  },
      { field: "title", headerName: "Linked Survey" , headerClassName: 'super-app-theme--header', width: 300 },
      {
        field: 'action',
        headerName: 'Action',
        headerAlign:'center',
        align:'center',
        type: 'actions',
        width: 350,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            
            
            return (
              <Stack direction="row" spacing={2} justifyItems={'center'}>
                <IconButton variant="outlined" color="warning" size="small" onClick={()=> onClickEdit(params)}><EditIcon/></IconButton>
                <IconButton variant="outlined" color="error" size="small" onClick={()=> onDelete(params)}><DeleteIcon/></IconButton>
              </Stack>
            );
        },
      }
    ];
    const AddNew  = (event)=> {
      event.preventDefault();

      axios.post(api_uri+"/emailtemplateadd", {            
            
        template_name:event.target.template_name.value,
        subject:event.target.subject.value,
        footer:event.target.footer.value,
        surveyid:event.target.surveyid.value,
        content:event.target.content.value,
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          // return response.json();
          call_fetch()
          setOpenadd(false)          
          toast.success("Record Added !", {position: "top-right",theme: "colored"});


        })
        .catch((error) => {
            swal("", "Failed to add", "error",{ button: "Try Again!",});
        });
      
    }
    const updaterecord  = (event)=> {
      event.preventDefault();

      axios.post(api_uri+"/emailtemplateupdate", {            
        id:event.target.id.value,     
        template_name:event.target.template_name.value,
        subject:event.target.subject.value,
        footer:event.target.footer.value,
        surveyid:event.target.surveyid.value,
        content:event.target.content.value,
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          call_fetch()
          setOpenedit(false)
          toast.success("Updated !", {position: "top-right",theme: "colored"});


        })
        .catch((error) => {
            swal("", "Failed to update", "error",{ button: "Try Again!",});
        });
      
    }
    const handleDeleteall = () => {
       
      const listid =selectedRows.join(',')
      if(listid){
        swal({
          title: "Are you sure you want to delete?",
          text: "",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {           
                  
            axios.post(api_uri+"/emaildeleteitems", {            
              ids:listid
          
              },{
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
              }
              })
              .then((response) => {
                call_fetch()
                if(selectedRows.length >1){
                  swal("Records Deleted!", "", "success",{ button: "Close",});
                }else{
                  swal("Record Deleted!", "", "success",{ button: "Close",});
                }
      
      
              })
              .catch((error) => {
                  swal("", "Failed to delete selected records!", "error",{ button: "Try Again!",});
              });
          } else {
            
          }
        });
      }else{
        swal("Please select records", "",{ button: "OK",});
      }
      

    }
    const options = surveylist.map((obj)=>
       <option key={obj.id}  value={obj.id} >{obj.title}</option>
    );
    
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
    return (
      <Box p="20px" sx={wider}>
        <Header
          title="Email Templates List"
          subtitle="Dashboard / Email Templates"
        />
        <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
            <Button onClick={handleOpenadd} color="primary" variant="contained" startIcon={<AddIcon />}>
              Add New Template
            </Button>
            <Button onClick={handleDeleteall} variant="contained" color="error" startIcon={<DeleteIcon />}>
              Delete Items
            </Button>
            
          </Stack>
        <Box
          m="20px 0 0 0"
          className="bg-white"
          
          
        >
          <DataGrid checkboxSelection disableRowSelectionOnClick
            rows={datalist}
            columns={columns}
            initialState={{ pinnedColumns: {  right: ['action'] } }}
            pageSizeOptions={[10, 25,50,100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(ids) => {
              // const selectedIDs = new Set(ids);
              // const selectedRows = datalist.filter((row) =>
              //   selectedIDs.has(row.id),
              // );
    
              setSelectedRows(ids);
            }}
          />
        </Box>

          <Modal
            open={openadd}
            onClose={handleCloseadd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Add New Email Template</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseadd}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={AddNew}>
                <div>
                  <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                      <div className='group-control'>
                        <label className="clabel">Template Name <span className='req-label'>*</span>:</label>
                        <input type="text" className="form-control" name="template_name" id="template_name" required/>
                      </div>
                      <div className='group-control'  style={{marginTop:'20px'}}>
                        <label className="clabel">Survey Link <span className='req-label'>*</span>:</label>
                        <select className="form-control" name="surveyid" id="surveyid" defaultValue={''} required>
                          <option disabled  value={''} >Select the Survey</option>
                          {options}
                        </select>
                      </div> 
                      <div className='group-control'  style={{marginTop:'20px'}}>
                        <label className="clabel">Email Subject <span className='req-label'>*</span>:</label>
                        <textarea className="form-control" name="subject" id="subject" required></textarea>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                       
                        
                      <div className='group-control'>
                        <label className="clabel">Email Content :</label>
                        <textarea className="form-control" rows="10" name="content" id="content" ></textarea>
                      </div>
                        <div className='group-control'>
                          <label className="clabel">Email Footer :</label>
                          <textarea className="form-control" rows="5" name="footer" id="footer" ></textarea>
                        </div>
                    </Grid>
                  </Grid>
                  
                  
                  
                  
                  <div className='bottom-control'>
                    <Button type="submit" variant='contained' color='success'>Save</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
          
          <Modal
            open={openedit}
            onClose={handleCloseedit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Edit Email Template</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseedit}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={updaterecord}>
                <div>
                  <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                      <div className='group-control'>
                        <label className="clabel">Template Name <span className='req-label'>*</span>:</label>
                        <input type="text" className="form-control" name="template_name" id="template_name" defaultValue={editdata.template_name} required/>
                      </div>
                      <div className='group-control'  style={{marginTop:'20px'}}>
                        <label className="clabel">Survey Link <span className='req-label'>*</span>:</label>
                        <select className="form-control" name="surveyid" id="surveyid" defaultValue={editdata.surveyid} required>
                          <option disabled  value={''} >Select the Survey</option>
                          {options}
                        </select>
                      </div> 
                      <div className='group-control'  style={{marginTop:'20px'}}>
                        <label className="clabel">Email Subject <span className='req-label'>*</span>:</label>
                        <textarea className="form-control" name="subject" id="subject" required>{editdata.subject}</textarea>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                       
                        
                      <div className='group-control'>
                        <label className="clabel">Email Content :</label>
                        <textarea className="form-control" rows="10" name="content" id="content" >{editdata.content}</textarea>
                      </div>
                        <div className='group-control'>
                          <label className="clabel">Email Footer :</label>
                          <textarea className="form-control" rows="5" name="footer" id="footer" >{editdata.footer}</textarea>
                        </div>
                    </Grid>
                  </Grid>
                  
                  
                  
                  
                  <div className='bottom-control'>
                    <input type='hidden' name="id" id="id" defaultValue={editdata.id}/>
                    <Button type="submit" variant='contained' color='secondary'>Update</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
          <ToastContainer autoClose={1000}/>
      </Box>
    );
  };

export default Emails


