import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "./Header";
import axios from "axios";
import swal from "sweetalert";
import dayjs from 'dayjs';

import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const wider ={
  width : '99%',
}
const styleadd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'white',
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb:4,
};

const api_uri = process.env.REACT_APP_API_URL

function Users() {
  
    const [Vendorlist, SetVendordata] = useState([])

    const [openadd, setOpenadd] = React.useState(false);
    const handleOpenadd = () => setOpenadd(true);
    const handleCloseadd = () => setOpenadd(false);

    
    const [openedit, setOpenedit] = React.useState(false);
    const handleOpenedit = () => setOpenedit(true);
    const handleCloseedit = () => setOpenedit(false);
    const [editdata, seteditdata] = useState([])

    const call_fetch = () =>{
      fetch(api_uri+"/userlist")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                SetVendordata(res)           
            }).catch((error) => {
                
            });
    }
    const onDelete = (e) => {
      const id = e.row.id;
      swal({
        title: "Are you sure you want to delete this user?",
        text: "",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) { 
            axios.get(api_uri+"/userdelete/"+id, {
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
              }
              })
              .then((response) => {
                call_fetch()
                toast.success("Record has been deleted !", {position: "top-right",theme: "colored"});
              })
              .catch((error) => {
                  swal("", "Fail to delete the record", "error",{ button: "Try Again!",});
              });
        }
      })
    };
    const onClickEdit = (e) => {
      seteditdata(e.row)
      handleOpenedit()
    };

    useLayoutEffect(() => {    
      
      
        call_fetch()
        
    },[]);
    
    
    const columns = [
      // { field: "id", headerName: "ID", headerClassName: 'super-app-theme--header', flex: 0.5 },
      { field: "username", headerName: "User Name", headerClassName: 'super-app-theme--header', width: 250 },
      { field: "email", headerName: "Email", headerClassName: 'super-app-theme--header',width: 250  },
      { field: "LoginDt", headerName: "Last Activity" , headerClassName: 'super-app-theme--header', width: 250 ,valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY')  },
      {
        field: 'action',
        headerName: 'Action',
        headerAlign:'center',
        align:'center',
        
        width: 100,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            
            
            return (
              <Stack direction="row" spacing={2} justifyItems={'center'}>
                
                <Tooltip title="Edit"><IconButton variant="outlined" color="warning" size="small" onClick={()=> onClickEdit(params)}><EditIcon/></IconButton></Tooltip>
                <Tooltip title="Delete"><IconButton variant="outlined" color="error" size="small" onClick={()=> onDelete(params)}><DeleteIcon/></IconButton></Tooltip>
                
              </Stack>
            );
        },
      }
    ];
    const handleDeleteall = () => {
       
      const listid =selectedRows.join(',')
      if(listid){
        swal({
          title: "Are you sure you want to delete?",
          text: "",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {           
                  
            axios.post(api_uri+"/userdeleteitems", {            
              ids:listid
          
              },{
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
              }
              })
              .then((response) => {
                call_fetch()
                swal("Deleted records!", "", "success",{ button: "Close",});
      
      
              })
              .catch((error) => {
                  swal("", "Failed to delete selected records!", "error",{ button: "Try Again!",});
              });
          } else {
            
          }
        });
      }else{
        swal("Please select records", "",{ button: "OK",});
      }
      

    }
    const AddNew  = (event)=> {
      event.preventDefault();

      axios.post(api_uri+"/adduser", {            
            
        
        email:event.target.email.value,
        name:event.target.name.value,
        password:event.target.password.value, 
        token:true
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          // return response.json();
          call_fetch()
          setOpenadd(false)
          toast.success("Record Added !", {position: "top-right",theme: "colored"});


        })
        .catch((error) => {
            swal("", "Failed to add", "error",{ button: "Try Again!",});
        });
      
    }
    const updaterecord  = (event)=> {
      event.preventDefault();

      axios.post(api_uri+"/updateuser", {            
        id:event.target.id.value,   
        email:event.target.email.value,
        name:event.target.name.value,
        password:event.target.password.value, 
    
        },{
          headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
          call_fetch()
          setOpenedit(false)
          toast.success("Updated !", {position: "top-right",theme: "colored"});


        })
        .catch((error) => {
            swal("", "Failed to update", "error",{ button: "Try Again!",});
        });
      
    }
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
    return (
      <Box p="20px" >
        <Header
          title="Users list"
          subtitle="Dashboard / Admin Users"
        />
        <Stack direction="row" spacing={2} justifyContent={'flex-start'}>
            
        <Button onClick={handleOpenadd} color="primary" variant="contained" startIcon={<AddIcon />}>
              Add New User
            </Button>
            
          </Stack>
        <Box
          m="20px 0 0 0"
          className="bg-white"
          sx={wider}
          
        >
          <DataGrid checkboxSelection disableRowSelectionOnClick
            rows={Vendorlist}
            columns={columns}
            pageSizeOptions={[10, 25,50,100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            components={{ Toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(ids) => {
                
              setSelectedRows(ids);
            }}
          />
        </Box>

        <Modal
            open={openadd}
            onClose={handleCloseadd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Add New User</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseadd}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={AddNew}>
                <div>
                  <Grid container spacing={2} >
                      <Grid item xs={12} md={12}>
                        <div className='group-control'>
                          <label className="clabel">User  Name :</label>
                          <input type="text" className="form-control" name="name" id="name" />
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Email <span className='req-label'>*</span>:</label>
                          <input type="text" className="form-control" name="email" id="email" required/>
                        </div>
                        
                        <div className='group-control'>
                          <label className="clabel">Password :</label>
                          <input type="text" className="form-control" name="password" id="password" />
                        </div>
                        

                      </Grid>
                  </Grid>
                  
                  <div className='bottom-control'>
                    <Button type="submit" variant='contained' color='success'>Save</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
          
          <Modal
            open={openedit}
            onClose={handleCloseedit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            
            <Box sx={styleadd}>
              <div  style={{display:'flex',justifyContent:'space-between'}}>
              <h3 className='md-title'>Edit User</h3>
              <Tooltip title="Close"><IconButton color="#000" className='modal-close'  onClick={handleCloseedit}>
                <CloseIcon/>
                </IconButton>
                </Tooltip>
              </div>
              <form onSubmit={updaterecord}>
                <div>
                  <Grid container spacing={2} >
                      <Grid item xs={12} md={12}>
                      <div className='group-control'>
                          <label className="clabel">User  Name :</label>
                          <input type="text" className="form-control" name="name" id="name" defaultValue={editdata.username} />
                        </div>
                        <div className='group-control'>
                          <label className="clabel">Email <span className='req-label'>*</span>:</label>
                          <input type="email" className="form-control" name="email" id="email" defaultValue={editdata.email} required/>
                        </div>
                        
                        <div className='group-control'>
                          <label className="clabel">Password :</label>
                          <input type="password" className="form-control" name="password" id="password"  defaultValue={editdata.password}/>
                        </div>

                      </Grid>
                  </Grid>
                  
                  <div className='bottom-control'>
                    <input type='hidden' name="id" id="id" defaultValue={editdata.id}/>
                    <Button type="submit" variant='contained' color='secondary'>Update</Button>
                  </div>

                </div>
              </form>
              
            </Box>
            
          </Modal>
        
        <ToastContainer autoClose={1000}/>
      </Box>
    );
  };

export default Users



