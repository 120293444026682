import { Typography, Box, useTheme, IconButton } from "@mui/material";
import { tokens } from "../theme";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

const Header = ({ title, subtitle }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  var uname  ='';
  const tokenDetailsString = localStorage.getItem('userDetails');
        if(tokenDetailsString){
          const UserName = JSON.parse(localStorage.getItem('userDetails'));
          uname =UserName.username
          let tokenDetails = JSON.parse(tokenDetailsString);
        if(tokenDetails.expireDate===null){
          localStorage.removeItem('userDetails');
          navigate('/login');
        }
        else{
        
            let expireDate = new Date(tokenDetails.expireDate);
            let todaysDate = new Date();         

            if (todaysDate > expireDate){  
              localStorage.removeItem('userDetails');          
              navigate('/login');
            }

        }
        }else{
          navigate('/login');
        }
 


  
  const logout=()=>{
    localStorage.removeItem('userDetails');
    navigate('/login')
  }

  return (
    <Box className="header-box" mb="30px" display={'flex'} justifyContent={'space-between'}>
      <Box >
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          className="dash-page-title"
          sx={{ m: "0 0 5px 0" }}
        >
         <DashboardOutlinedIcon/> {title}
        </Typography>
        <Typography variant="h5" className="dash-page-subhead">
          {subtitle}
        </Typography>
      </Box>
      
      <div>
        {/* <div>{UserName.username}</div> */}
      
      <div className="dropdn">
          <IconButton  className="dropbtn">
              <PersonOutlinedIcon /> 
          </IconButton>
        <div className="dropdn-content">
          <div className="username">{uname}</div>
          <div className="logout" onClick={logout}>Logout <LogoutIcon/></div>
        </div>
      </div>
      
        
      </div>
      
    </Box>
  );
};

export default Header;
