import React, { useLayoutEffect, useRef,useState } from 'react'
import { Box  ,Stack, Grid, Card, Button} from "@mui/material";
import Header from "./Header";
import { useReactToPrint } from 'react-to-print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import AnswerSum from './AnswerSum';

const api_uri = process.env.REACT_APP_API_URL



const SingleReport = () => {

  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("survey")

  const bank = {
    surveyid:id,
    elements:[],

  };
  const reportref = useRef();
  const handlePrintdata = useReactToPrint({
    content: () => reportref.current,
  });

  const [singleData, setsingleData] = useState([])
  const [ansData, setansData] = useState([])
  const [formData, setFormData]= useState(bank);
  const [optarray, setoptarray]= useState([])

  const  [answers,setanswers] =useState([])
  const call_fetch = () =>{
    fetch(api_uri+"/survey/"+id)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
            setsingleData(res[0])   
            if(res[0].questions){
              setFormData(JSON.parse(res[0].questions))
            }        
          }).catch((error) => {
              
          });
    fetch(api_uri+"/singlereport/"+id)
          .then((response) => {
              return response.json();
          })
          .then((res) => {
              setansData(res)
              
              var ansi =[]
              const filarray = res.map((ans,index)=>{
                if(ans.answer){
                  const single_ans =JSON.parse(ans.answer.replace(/\\'/g, "\'"))  
                
                   ansi.push(single_ans)
                }
                
               
              })    
               setanswers(ansi)      
              
                   
          }).catch((error) => {
              
          });
  }
  

  useLayoutEffect(() => {    
      
      
    call_fetch()  


    
  },[]);

  

  const Question = formData.elements.map((qes,index)=> 
      
  <AnswerSum key={index} id={index+1} props={qes} formData={formData} answers={answers} optarray={optarray}/>
    

  )
  const css = `
    @media print {
      @page {  margin:5px}
    }
`

  return (
    <Box p="20px" >
      <style>{css}</style>
        <Header
          title={singleData.title}
          subtitle={"Dahboard / Survey Reports / Survey Questions Summaries"}
        />

          <Stack container direction="row" paddingInlineEnd={20} spacing={2} justifyContent={'flex-end'}>
            
            <Button onClick={handlePrintdata} variant="contained" color="success" startIcon={<FileDownloadIcon />}>
              Export
            </Button>
            
          </Stack>
        <Box
          m="20px"
          className="bg-white" 
        >
          <Grid container spacing={0} display="flex" justifyContent={'center'}>
           
               
            <Grid item xs={12} md={12}   >
              <Card className="sum-card" ref={reportref}  >
                   
                  
                {Question}
                

                </Card>
            </Grid>
          </Grid>

        </Box>

         
        
      </Box>
  )
}

export default SingleReport
