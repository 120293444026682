import React, { useEffect } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';

import TextField from '@mui/material/TextField';

import { agencies,zipcode } from './data';
import RemoveArr from './mini/RemoveArr';
import { Link } from 'react-router-dom';

function findes(opt,index){
    const opt1 = opt.toString()
    return <option key={index}> {opt} </option>
}
const style = {
    '& .MuiSvgIcon-root': {
      fontSize: 28,
    },
  }

function Singlequestions({formData, setFormData,qesData,id,optarray,setquesno,setquestype}) {


    const onChange = (e) => {
       
        const {value,name,type} = e.target;
        if(type === 'checkbox'){
            
        }
        else if(type === 'email'){
            const qnam =e.target.id;
            console.log(qnam)

            setFormData({...formData, [qnam]: value,[name]: value})
            
        }
        else{
            setFormData({...formData, [name]: value})
        }
        
        
        
    }
    const onCheck = (e) => {
        const {value,name,checked} = e.target;
        let array = []
        if(formData[name]){
            array = formData[name]
        }
        
        if(checked){
            if(name === 'qes_16' || name === 'qes_22' ){           
                if(array.length ===3){

                }else{
                    array.push(value)
                    setFormData({...formData, [name] : array  })
                }

            }else{
                array.push(value)
                setFormData({...formData, [name] : array  })
            }
            
            
        }else{
            RemoveArr(array,value)
            setFormData({...formData, [name] : array})
        }
        
        
    }
    let onedata = [];
    let newid =id-1
    if(optarray.length > 0){
        const alldatas = qesData['elements'].filter((x) => optarray.includes(x.qesid.toString()))
        
        const alldata = alldatas.filter((item, index) => index === newid)
        onedata = alldata[0]
        
    }else{
        const alldata = qesData['elements'].filter((item, index) => index === newid)
        onedata = alldata[0]
    }
    setquesno(onedata.qesid)
    setquestype(onedata.type)

    

    useEffect(() => {  

      
      
        // if(formData['question'+onedata.qesid]){
        //     console.log(formData['question'+onedata.qesid]);
        //     // setvalue(formData['question'+onedata.qesid])

        // }   
            
    },[]);

    if(onedata.type ==='radio'){
        
        
        const radio = onedata.choices.map((cho,index)=> 
      
        <FormControlLabel key={index} className="radio-field" labelPlacement='start' value={cho.value} control={<Radio  sx={style}/>} label={cho.text} />
            

        )

        return (
            <div className='user-qes-card'>
                <div className='user-question'>QUESTION {id}</div>
                <div className='user-qes-label'>{onedata.title}</div>
                <div className='user-qes-short'>{onedata.description ? '('+onedata.description+')':''}</div>
                <div className='user-qes-field'>
                    <RadioGroup className=""
                    aria-labelledby=""
                    value={formData[onedata.qesid]? formData[onedata.qesid]:false}
                    name={onedata.qesid}
                    onChange={onChange}
                    required
                        >
                    {radio}
                    </RadioGroup>
                </div>
                <div className='user-qes-note'>{onedata.note ? 'Note : '+onedata.note:''}</div>
                {
                    (onedata.qesid==='qes_18' && formData['qes_18']==='1'?
                        <div className='select-box'>
                            <select required value={formData[onedata.qesid+'_1']? formData[onedata.qesid+'_1']:false} onChange={onChange} name={onedata.qesid+'_1'} className='select-feild'>
                                <option selected > Select your county limits </option>
                                {zipcode.map((opt,index)=> findes(opt,index) )}
                                
                            </select>
                        </div>
                    :"")
                }
                {   

                    (onedata.qesid==='qes_21' && formData['qes_21']==='1'?
                        <div className='select-box'>
                            <select required value={formData[onedata.qesid+'_1']? formData[onedata.qesid+'_1']:false} onChange={onChange} name={onedata.qesid+'_1'} className='select-feild'>
                                <option selected  > Select the public agency </option>
                                {agencies.map((opt,index)=> <option key={index}> {opt} </option> )}                                
                            </select>
                            {(formData[onedata.qesid+'_1']==="Others"?<div className='sub-text-box'>
                            <label className='sub-label'>Please provide the public agency name:</label>
                                 <TextField
                                id="standard-normal"
                                variant="standard"
                                type="text"
                                fullWidth  
                                value={formData[onedata.qesid+'_2']? formData[onedata.qesid+'_2']:''} onChange={onChange} name={onedata.qesid+'_2'}
                                required
                                />
                                </div>:'')}
                        </div>
                        

                    :"")
                }
                {
                    (onedata.qesid==='qes_17' && formData['qes_17']==='14'?
                        <div className='select-box'>
                            <label className='sub-label'>Please provide information about your firm:</label>
                            <TextField
                                id="standard-normal"
                                variant="standard"
                                type="text"
                                placeholder='Please speciy'
                                fullWidth  
                                value={formData[onedata.qesid+'_1']? formData[onedata.qesid+'_1']:''} onChange={onChange} name={onedata.qesid+'_1'}
                                required
                                />
                        </div>
                    :"")
                }

                
                
                
            </div>
          )

    }else if(onedata.type ==='checkbox'){

        const checkbox = onedata.choices.map((cho,index)=> 
      
        <FormControlLabel key={index} className="radio-field" name={onedata.qesid}  onChange={onCheck}  checked={formData[onedata.qesid]? formData[onedata.qesid].includes(cho.value.toString()):false} labelPlacement='start' value={cho.value} control={<Checkbox  sx={style}/>} label={cho.text} />
            

        )

        return (
            <div className='user-qes-card'>
                <div className='user-question'>QUESTION {id}</div>
                <div className='user-qes-label'>{onedata.title}</div>
                <div className='user-qes-short'>{onedata.description ? '('+onedata.description+')':''}</div>
                <div className='user-qes-field'>
                    <FormGroup >
                    {checkbox}
                    </FormGroup>
                </div>
                <div className='user-qes-note'>{onedata.note ? 'Note : '+onedata.note:''}</div>
                
              
            </div>
          )

    }else if(onedata.type ==='multiboolean'){

        const radio = onedata.choices.map((cho,index)=> 
            <div className='multiboolen' key={index}>
                <label>{cho.text}</label>
                <RadioGroup className="" row
                    aria-labelledby=""
                    value={formData[onedata.qesid+'_'+cho.value]? formData[onedata.qesid+'_'+cho.value]:false}
                    name={onedata.qesid+'_'+cho.value}
                    onChange={onChange}
                    required
                        >
                    <FormControlLabel  className="radio-field"  value={1} control={<Radio  sx={style}/>} label="Yes" />
                    <FormControlLabel  className="radio-field"  value={2} control={<Radio  sx={style}/>} label="No" />
                    <FormControlLabel  className="radio-field"  value={3} control={<Radio  sx={style}/>} label="Neutral" />
       
                </RadioGroup>
                
            </div>
      
        
            

        )

        return (
            <div className='user-qes-card textarea'>
                <div className='user-question'>QUESTION {id}</div>
                <div className='user-qes-label'>{onedata.title}</div>
                <div className='user-qes-short'>{onedata.description ? '('+onedata.description+')':''}</div>
                <div className='user-qes-field'>                    
                    {radio}
                </div>
                <div className='user-qes-note'>{onedata.note ? 'Note : '+onedata.note:''}</div>
                
              
            </div>
          )

    }else if(onedata.type ==='textarea'){

    

        return (
            <div className='user-qes-card textarea'>
                <div className='user-question'>QUESTION {id}</div>
                <div className='user-qes-label'>{onedata.title}</div>
                <div className='user-qes-short'>{onedata.description ? '('+onedata.description+')':''}</div>
                <div className='user-qes-field'>
                    <TextField
                    id="standard-textarea"
                    variant="standard"
                    multiline
                    placeholder="Type here"
                    fullWidth  
                    name={onedata.qesid}             
                    value={formData[onedata.qesid]? formData[onedata.qesid]:''}
                    onChange={onChange}
                    required
                    />
                </div>
                <div className='user-qes-note'>{onedata.note ? 'Note : '+onedata.note:''}</div>
                
              
            </div>
          )

    }else if(onedata.type ==='email'){

            

        return (
            <div className='user-qes-card textarea'>
                <div className='user-question'>QUESTION {id}</div>
                <div className='user-qes-label'>{onedata.title}</div>
                <div className='user-qes-short'>{onedata.description ? '('+onedata.description+')':''}</div>
                <div className='user-qes-field'>
                    <div className='contact-frm'>
                        <Grid container spacing={0} display="flex" justifyContent={'center'}>
                        <Grid item xs={12} md={12}   >
                            <div className='group-ct'>
                            <label>Business Name</label>
                            <TextField
                                id="standard-normal"
                                variant="standard"
                                placeholder="Enter Business Name"
                                fullWidth  
                                name={"BName"}             
                                value={formData['BName']? formData['BName']:''}
                                onChange={onChange}
                                required
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}   ><div className='group-ct'><label>Business Contact :</label></div></Grid>
                        <Grid item xs={12} md={6}   >
                            <div className='group-ct'>
                            <label>First Name</label>
                            <TextField
                                id="standard-normal"
                                variant="standard"
                                placeholder="Enter First Name"
                                fullWidth  
                                name={"FName"}             
                                value={formData['FName']? formData['FName']:''}
                                onChange={onChange}
                                required
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}   >
                            <div className='group-ct'>
                            <label>Last Name</label>
                            <TextField
                                id="standard-normal"
                                variant="standard"
                                placeholder="Enter Last Name"
                                fullWidth  
                                name={"LName"}             
                                value={formData['LName']? formData['LName']:''}
                                onChange={onChange}
                                required
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}   >
                            <div className='group-ct'>
                            <label>Business Email</label>
                            <TextField
                                id={onedata.qesid} 
                                variant="standard"
                                type='email'
                                placeholder="Enter Business Email Address"
                                fullWidth  
                                qname={onedata.qesid} 
                                name={"email"}            
                                value={formData[onedata.qesid]? formData[onedata.qesid]:''}
                                onChange={onChange}
                                required
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}   >
                            <div className='group-ct'>
                            <label>Business Phone</label>
                            <TextField
                                id="standard-normal"
                                variant="standard"
                                type='number'
                                placeholder="Enter Business Phone"
                                fullWidth  
                                name={"BPhone"}             
                                value={formData["BPhone"]? formData['BPhone']:''}
                                onChange={onChange}
                                required
                                inputProps={{pattern: ".{7,15}"}}
                                />
                            </div>
                        </Grid>
                        </Grid>            
                    </div> 
                    
                </div>
                <div className='user-qes-note'>{onedata.note ? 'Note : '+onedata.note:''}</div>
                
              
            </div>
          )

    }else if(onedata.type ==='number'){

    

        return (
            <div className='user-qes-card textarea'>
                <div className='user-question'>QUESTION {id}</div>
                <div className='user-qes-label'>{onedata.title}</div>
                <div className='user-qes-short'>{onedata.description ? '('+onedata.description+')':''}</div>
                <div className='user-qes-field'>
                    <TextField
                    id="standard-normal"
                    variant="standard"
                    type="number"
                    fullWidth  
                    name={onedata.qesid}             
                    value={formData[onedata.qesid]? formData[onedata.qesid]:'0'}
                    onChange={onChange}
                    required
                    />
                </div>
                <div className='user-qes-note'>{onedata.note ? 'Note : '+onedata.note:''}</div>
                
              
            </div>
          )

    }else if(onedata.type ==='text'){

    

        return (
            <div className='user-qes-card textarea'>
                <div className='user-question'>QUESTION {id}</div>
                <div className='user-qes-label'>{onedata.title}</div>
                <div className='user-qes-short'>{onedata.description ? '('+onedata.description+')':''}</div>
                <div className='user-qes-field'>
                    <TextField
                    id="standard-normal"
                    variant="standard"
                    type="text"
                    fullWidth  
                    name={onedata.qesid}             
                    // value={formData.question_19}
                    onChange={onChange}
                    required
                    />
                </div>
                <div className='user-qes-note'>{onedata.note ? 'Note : '+onedata.note:''}</div>
                
              
            </div>
          )

    }else{
        return (
            <div>
                
              
            </div>
          )
    }

  
}

export default Singlequestions
