// import { useState } from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
// import Topbar from "./admin/global/Topbar";
import Sidemenu from "./admin/global/Sidemenu";
import Dashboard from "./admin/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Surveys from "./admin/surveys";
import Vendors from "./admin/vendors";
import Projects from "./admin/projects";
import Contractors from "./admin/contractors";
import Users from "./admin/users";
import Pie from "./admin/charts/pie";
import "./admin/global/Sidemenu.css";
import Emails from "./admin/emails";
import SurveyQes from "./admin/SurveyQes";
import Reports from "./admin/Reports";
import SurveyResults from "./admin/SurveyResults";
import SurveyAnswer from "./admin/SurveyAnswer";
import SurveyReports from "./admin/SurveyReports";
import SingleReport from "./admin/SingleReport";
import { useEffect } from "react";
import SurveyDash from "./admin/SurveyDash";
import SurveyGuide from "./admin/SurveyGuide";

const Layout = () => {
    const [theme, colorMode] = useMode();
    // const [isSidebar, setIsSidebar] = useState(true);
    return (
    <>
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    <Sidemenu isSidebar={true} />
                    <main className="content main-area">
                        {/* <Topbar setIsSidebar={setIsSidebar}  /> */}
                        <Outlet />
                    </main>
                </div>
            </ThemeProvider>
            </ColorModeContext.Provider>
    </>
    );
};
function Admin() {

    const navigate = useNavigate();
    useEffect(() => { 
        
        const tokenDetailsString = localStorage.getItem('userDetails');
        if(!tokenDetailsString){
            navigate('/login', {replace:true});
        }
    
    },[]);
    
        return (
            <Routes >
                <Route element={<Layout />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/survey-dash" element={<SurveyDash/>} />
                    <Route path="/surveys" element={<Surveys />} />
                    <Route path="/vendors" element={<Vendors />} />
                    <Route path="/projects" element={<Projects />} />                    
                    <Route path="/contractors" element={<Contractors />} />                
                    <Route path="/users" element={<Users />} />                               
                    <Route path="/emails" element={<Emails />} />                                      
                    <Route path="/survey-questions" element={<SurveyQes />} />                            
                    <Route path="/survey-results" element={<SurveyResults />} />
                    <Route path="/survey-reports" element={<SurveyReports />} />
                    <Route path="/survey-summary" element={<SingleReport />} />
                    <Route path="/survey-answer" element={<SurveyAnswer />} />
                    <Route path="/survey-guide" element={<SurveyGuide/>} />
                    <Route path="/reports" element={<Reports/>} />
                    <Route path="/pie" element={<Pie />} />
                </Route>
              
            </Routes>
        );
    }

  


export default Admin;
