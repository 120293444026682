// import { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Topbar from "./vendor/Topbar";
import Index from "./vendor";
import Thank from "./survey/thank";
import "./vendor/user.css"
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useModelight } from "./theme";
import SurveyForm from "./survey/surveyform";
import SurveyHome from "./survey";
import Register from "./vendor/register";
import Forgot from "./vendor/forgot";

const Layout = () => {
    const [theme, colorMode] = useModelight();
    return (
    <>
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    <main className="content">
                        <Topbar  />
                        <Outlet /> 
                    </main>
                </div>
            </ThemeProvider>
            </ColorModeContext.Provider>
    </>
    );
};
function App() {
    
    
    return (
    
            <Routes >
                <Route element={<Layout />}>
                    <Route index path="/" element={<SurveyHome/>} />
                    <Route path="/login" element={<Index/>} />
                    <Route path="/register" element={<Register/>} />
                    <Route path="/forgot-password" element={<Forgot/>} />
                    <Route path="/survey-form" element={<SurveyForm />} />
                    <Route path="/thankyou-page" element={<Thank />} />
                </Route>
                
            </Routes>
    );
}

export default App;


